import * as tslib_1 from "tslib";
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { OnInit, OnDestroy, QueryList } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { SsrBoxComponent } from '../ssr-box/ssr-box.component';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
import { ApplicationFlowEnum, BookingStepsService } from 'src/app/core/booking-steps.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import * as moment from 'moment';
export class SsrsComponent {
    constructor(flowManager, ssrsService, bookingFlowService, appFlowSteps, resourcesService) {
        this.flowManager = flowManager;
        this.ssrsService = ssrsService;
        this.bookingFlowService = bookingFlowService;
        this.appFlowSteps = appFlowSteps;
        this.resourcesService = resourcesService;
        this.SsrTypeEnum = SsrType;
        this.flights = [];
        this.journeys = [];
        this.paxSegments = [];
        this.hasEarlyCheckin = false;
        if (this.appFlowSteps.flow === ApplicationFlowEnum.Booking) {
            this.isBookingFlow = true;
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(flights => {
                if (flights) {
                    this.flights = flights;
                    this.removeCanceledFlights();
                }
            });
            this.bookingFlowService.loadFlowInfo().then(booking => {
                if (booking &&
                    booking.convertedJourneys &&
                    booking.convertedJourneys.journeys &&
                    booking.convertedJourneys.journeys.length > 0) {
                    this.journeys = booking.convertedJourneys.journeys;
                }
                if (booking &&
                    booking.bundleDetail &&
                    booking.bundleDetail.bundleCodes.find(el => el.find(item => item.value == "ABAS")) != undefined) {
                    this.hasEarlyCheckin = true;
                }
            });
            var flexInactivePeriods = yield this.resourcesService.getFlexInactivePeriodAsync();
            const now = moment();
            this.isInactiveFlex = flexInactivePeriods.some(p => now.isSameOrAfter(moment.utc(p.flexPeriodStartDate, 'YYYY-MM-DD HH:mm'))
                && now.isSameOrBefore(moment.utc(p.flexPeriodEndDate, 'YYYY-MM-DD HH:mm')));
        });
    }
    ngOnDestroy() {
        if (this.flightsSubscription) {
            this.flightsSubscription.unsubscribe();
        }
    }
    getPendingSsrs() {
        this.ssrBoxes.forEach(b => b.applySsrs());
        return this.ssrsService.getPendingSsrs();
    }
    getComponentModals() {
        return this.ssrBoxes.filter(x => x.validationModalComponent
            && x.validationModalComponent.modalComponents.length > 0)
            .map(x => x.validationModalComponent);
    }
    selectMeal() {
        this.isMealSelected = true;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = false;
    }
    selectPet() {
        this.isMealSelected = false;
        this.isPetSelected = true;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = false;
        this.checkPassengerLiftStatus();
    }
    selectSpecialEquipment() {
        this.isMealSelected = false;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = true;
        this.isSpecialAssistanceSelected = false;
        this.checkPassengerLiftStatus();
    }
    selectSpecialAssistance() {
        this.isMealSelected = false;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = true;
        this.checkPassengerLiftStatus();
    }
    checkPassengerLiftStatus() {
        for (const j of this.journeys) {
            if (j.sellKey == this.flights[0].sellKey &&
                j.segments &&
                j.segments.length > 0 &&
                j.segments[0].paxSegments &&
                j.segments[0].paxSegments.length > 0) {
                this.paxSegments = j.segments[0].paxSegments;
                break;
            }
        }
        for (const p of this.paxSegments) {
            if (p.liftStatus === "CheckedIn") {
                this.isUnavailableDueToPreviouslyCheckedIn = true;
                break;
            }
        }
    }
    removeCanceledFlights() {
        let flightsDepartureStations = [];
        for (let f = 0; f < this.flights.length; f++) {
            for (const s of this.flights[f].segments) {
                for (const l of s.legs) {
                    if (l.legInfo.status === "Canceled") {
                        flightsDepartureStations.push(this.flights[f].departureStation.name);
                    }
                }
            }
        }
        if (flightsDepartureStations.length > 0) {
            for (let f = 0; f < this.flights.length; f++) {
                for (let i = 0; i < flightsDepartureStations.length; i++) {
                    if (this.flights[f].departureStation.name === flightsDepartureStations[i]) {
                        this.flights.splice(f, 1);
                    }
                }
            }
        }
    }
}
