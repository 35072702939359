import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { UserStateService } from '../common-modules/blue-air-common/user-state.service';
import { BookingFlowService } from '../core/booking-flow.service';
import { BookingService } from '../core/booking.service';
import { ConfigService } from '../core/config.service';
import * as moment from 'moment';
import { ChangeFlightModel } from '../itinerary/booking-info/booking-info.component';
import { DefaultModalComponent } from '../shared/default-modal/default-modal.component';
export class BookingConsentComponent {
    constructor(activatedRoute, translateService, userStateService, bookingService, bookingFlowService, sessionService, configService) {
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.userStateService = userStateService;
        this.bookingService = bookingService;
        this.bookingFlowService = bookingFlowService;
        this.sessionService = sessionService;
        this.configService = configService;
        this.recordLocator = "";
        this.emailAddress = "";
        this.lastName = "";
        this.dynamicUrl = "";
        this.showConsentOptions = true;
        this.isConsentYes = false;
        this.isConsentNo = false;
        this.isButtonSelected = false;
        this.isRefundToCash = false;
        this.isPassengerEligibleToSubmit = false;
        this.isDirectFlight = false;
        this.isIndirectFlight = false;
        this.isCommercialFlight = false;
        this.disableRefund = false;
        this.showErrorMessage = false;
        this.hasCanceledFlights = false;
        this.hasTimeChangedFlights = false;
        this.hasInsurance = false;
        this.missingParams = false;
        this.bookingNotFound = false;
        this.memberIsLoggedIn = false;
        this.memberNotLoggedIn = false;
        this.wrongMemberRetrieve = false;
        this.isLoading = true;
        this.journeys = [];
        this.flightsToChange = [];
        this.changeFlight = new ChangeFlightModel();
        this.passengers = [];
        this.changeDateActive = false;
        this.moveFlight = false;
        this.modalId = 'refundModal';
        this.queuesEligibleToSubmit = ['CASREF', 'CXLSM', 'CXLSW', 'RMO3AC', 'RMO3SM', 'RMO3SW', 'SCO3AC', 'SCO3SM', 'SCO3SW'];
        this.hasInsuranceFee = false;
        this.hasInsuranceQueueCode = false;
        this.insuranceModalId = 'insuranceModal';
    }
    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if (Object.getOwnPropertyNames(params).length !== 0) {
                this.recordLocator = params['rl'];
                this.emailAddress = params['em'];
                this.lastName = params['ln'];
            }
        });
        this.getBooking();
    }
    ngOnDestroy() {
        localStorage.removeItem("pageState");
    }
    getBooking() {
        if (!(this.recordLocator && (this.emailAddress || this.lastName))) {
            this.missingParams = true;
            return;
        }
        this.sessionService.ensureSessionIsValid()
            .then(() => this.bookingService.retrieveBooking(this.recordLocator, this.lastName, this.emailAddress, this.configService.BlueRetrieve))
            .then(retrievedBooking => {
            if (retrievedBooking) {
                this.memberNotLoggedIn = false;
                this.memberIsLoggedIn = retrievedBooking.bookingDetail.pointOfSale.organizationCode == "BAMEMBERS" ? this.userStateService.isLoggedIn.getValue() : true;
                if (!this.memberIsLoggedIn) {
                    let openLoginModal = this.loginModalId.nativeElement;
                    openLoginModal.click();
                }
            }
            else {
                this.bookingNotFound = true;
            }
        }, e => {
            const data = e.error.errors;
            if (data && data.find(i => i.errorCode === ErrorType[ErrorType.memberNotLoggedIn])) {
                this.memberNotLoggedIn = true;
                if (!this.memberIsLoggedIn) {
                    let openLoginModal = this.loginModalId.nativeElement;
                    openLoginModal.click();
                }
            }
            else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.ValidateNullBooking])) {
                this.bookingNotFound = true;
                this.showErrorMessage = true;
            }
            else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.wrongMemberRetrieve])) {
                this.wrongMemberRetrieve = true;
                this.showErrorMessage = true;
            }
        })
            .then(() => {
            this.bookingFlowService.loadFlowInfo(true)
                .then(booking => {
                if (booking) {
                    if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
                        this.isPassengerEligibleToSubmit = !booking.bookingQueueInfos.items.some(q => this.queuesEligibleToSubmit.includes(q.queueCode));
                    }
                    if (booking.convertedJourneys && booking.convertedJourneys.journeys && booking.convertedJourneys.journeys.length > 0) {
                        this.journeys = booking.convertedJourneys.journeys;
                        this.checkFlightType();
                        this.disableRefund = this.checkLiftStatus();
                        this.hasTimeChangedFlights = this.journeys.some(j => j.segments && j.segments.some(s => s.paxSegments && s.paxSegments[0].timeChanged == true));
                        this.hasCanceledFlights = this.journeys.some(j => j.segments && j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled')));
                        if (this.hasCanceledFlights) {
                            this.journeys.forEach(j => {
                                j.hasCancelLeg = j.segments && j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled'));
                            });
                        }
                        if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
                            this.passengers = booking.passengers.items;
                        }
                        this.hasInsurance = this.journeys.some(j => j.segments.some(s => s.paxSSRs.find(ssr => ssr.sSRCode.match("INS1"))));
                        if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
                            this.hasInsuranceFee = this.passengers.some(p => p.fees && p.fees.items.some(f => f.code == "XCOVT1"));
                        }
                        if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
                            this.hasInsuranceQueueCode = booking.bookingQueueInfos.items.some(q => q.queueCode == "INS");
                        }
                        if (this.checkPastFlights()) {
                            this.showErrorMessage = true;
                            this.bookingService.addComment(this.recordLocator, 'Error: Flights in the past', this.emailAddress, true);
                        }
                        this.showErrorMessage = !this.hasEligibleFlightsToSubmitPNR() || this.checkLiftStatus();
                    }
                }
            })
                .then(() => {
                if (!localStorage.getItem("pageState")) {
                    localStorage.setItem("pageState", "reloaded");
                    window.location.reload();
                }
            });
        });
    }
    checkConsent(buttonChosen) {
        if (buttonChosen) {
            this.showConsentOptions = false;
            this.isConsentYes = true;
            this.isConsentNo = false;
            this.isButtonSelected = false;
            this.bookingService.setPassengerConsent(this.emailAddress);
        }
        else if (!buttonChosen && !this.disableRefund) {
            this.showConsentOptions = false;
            this.isConsentYes = false;
            this.isConsentNo = true;
            this.isButtonSelected = true;
        }
    }
    enableConsentOptions() {
        this.showConsentOptions = true;
        this.isConsentYes = false;
        this.isConsentNo = false;
        this.isButtonSelected = false;
    }
    goToCustomerSupportCenter() {
        this.dynamicUrl = "https://help.flyblueair.com" + /* this.translateService.culture.replace("-", "/").toLocaleLowerCase() + */ "/hc/en-us";
    }
    manageMyBooking() {
        if (this.disableRefund)
            return;
        window.location.href = window.location.origin + "/" + this.translateService.culture.replace("-", "/").toLocaleLowerCase() +
            "/booking-engine/booking-status" + "?rl=" + this.recordLocator + "&em=" + this.emailAddress + "&ln=" + this.lastName;
    }
    whereToRefund() {
        this.refundModal.openPopup(this.modalId);
    }
    openLoginPopup() {
        window.commonFunctions.openLoginPopup();
    }
    refundToWallet() {
        this.refundModal.cancel();
        if (this.hasInsuranceFee || this.hasInsuranceQueueCode) {
            this.insuranceModal.ok();
            this.insuranceModal.openPopup(this.insuranceModalId);
        }
        else {
            this.refundToWalletProceed();
        }
    }
    refundToWalletProceed() {
        this.bookingFlowService.loadFlowInfo(true).then(booking => {
            if (booking) {
                this.bookingService.recordLocator = this.recordLocator;
                window.location.href = window.location.origin + "/" + this.translateService.culture.replace("-", "/").toLocaleLowerCase() + "/booking-engine/ptw?step=1";
            }
        });
    }
    refundToCash() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var result = yield this.bookingService.refundCashBooking(this.emailAddress);
            if (result.customerRefundCashRequest.errors.length > 0) {
                this.bookingService.addComment(this.recordLocator, 'Error: ' + result.customerRefundCashRequest.errors[0], this.emailAddress, true);
                this.refundModal.cancel();
                this.showErrorMessage = true;
            }
            else {
                this.isRefundToCash = true;
                this.refundModal.cancel();
            }
        });
    }
    getStation(index, journey) {
        if (journey) {
            if (index == 0)
                return journey.segments[0].departureStation;
            if (journey.segments.length == 1)
                return journey.segments[0].arrivalStation;
            return journey.segments[journey.segments.length - 1].arrivalStation;
        }
    }
    formatDate(date, format) {
        return moment(date).format(format);
    }
    subtractTimezoneOffset(date, offset) {
        return moment(date).utcOffset(offset, true);
    }
    calculateWaitingTimeBetweenFLights(stopStationArrivalDate, stopStationDepartureDate) {
        let intermediateTime = new Date(stopStationDepartureDate).valueOf() - (new Date(stopStationArrivalDate)).valueOf();
        let days = Math.floor(intermediateTime / (60 * 60 * 24 * 1000));
        let hours = Math.floor(intermediateTime / (60 * 60 * 1000)) - (days * 24);
        let minutes = Math.floor(intermediateTime / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        let seconds = Math.floor(intermediateTime / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
        let waitingTime = "";
        if (days) {
            waitingTime = waitingTime + days + " DAYS ";
        }
        if (days && hours) {
            waitingTime = waitingTime + " and ";
        }
        if (hours) {
            waitingTime = waitingTime + hours + " HOURS ";
        }
        if (hours && minutes) {
            waitingTime = waitingTime + " and ";
        }
        if (minutes) {
            waitingTime = waitingTime + minutes + " MINUTES ";
        }
        if (minutes && seconds) {
            waitingTime = waitingTime + " and ";
        }
        if (seconds) {
            waitingTime = waitingTime + seconds + " SECONDS ";
        }
        waitingTime = waitingTime + "connection";
        return waitingTime;
    }
    checkLiftStatus() {
        return this.journeys.some(j => j.segments.some(s => s.paxSegments.some(ps => ps.liftStatus != "Default")));
    }
    checkPastFlights() {
        var nowMoment = moment().utc();
        var check = this.journeys.some(function (j) {
            var isBefore = j.segments.some(function (s) {
                var std = moment(s.sTD);
                return std.isBefore(nowMoment);
            });
            return isBefore;
        });
        return check;
    }
    isNextDay(departureDate, arrivalDate) {
        if (moment(departureDate).date() !== moment(arrivalDate).date()) {
            return true;
        }
    }
    hasEligibleFlightsToSubmitPNR() {
        let checker = false;
        if (this.hasTimeChangedFlights || this.hasCanceledFlights) {
            checker = true;
        }
        return checker;
    }
    checkFlightType() {
        if (this.journeys.some(j => j.segments && j.segments.some(s => s.legs && s.legs.length >= 2))) {
            this.isCommercialFlight = true;
        }
        else if (this.journeys.some(j => j.segments && j.segments.length >= 2)) {
            this.isIndirectFlight = true;
        }
        else {
            this.isDirectFlight = true;
        }
    }
}
export var ErrorType;
(function (ErrorType) {
    ErrorType[ErrorType["mandatoryFields"] = 1] = "mandatoryFields";
    ErrorType[ErrorType["bookingNotFound"] = 2] = "bookingNotFound";
    ErrorType[ErrorType["pendingPayments"] = 3] = "pendingPayments";
    ErrorType[ErrorType["checkinClosed"] = 4] = "checkinClosed";
    ErrorType[ErrorType["checkinNotOpen"] = 5] = "checkinNotOpen";
    ErrorType[ErrorType["memberNotLoggedIn"] = 6] = "memberNotLoggedIn";
    ErrorType[ErrorType["ValidateNullBooking"] = 7] = "ValidateNullBooking";
    ErrorType[ErrorType["wrongMemberRetrieve"] = 8] = "wrongMemberRetrieve";
})(ErrorType || (ErrorType = {}));
