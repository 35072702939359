import { ConfigService } from '../core/config.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../core/config.service";
export class FindMyBookingsService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    RetrieveBookingsByEmailAddress(RetrieveBookingsByEmailAddress) {
        RetrieveBookingsByEmailAddress.FlightNumber = RetrieveBookingsByEmailAddress.FlightNumber ?
            RetrieveBookingsByEmailAddress.FlightNumber.padStart(4, ' ') : null;
        return this.RetrieveBookings({ RetrieveBookingsByEmailAddress });
    }
    RetrieveBookingsByRecordLocator(RetrieveBookingsByRecordLocator) {
        return this.RetrieveBookings({ RetrieveBookingsByRecordLocator });
    }
    RetrieveBookingsByBookingDate(RetrieveBookingsByUtc) {
        RetrieveBookingsByUtc.EndUtc = RetrieveBookingsByUtc.StartUtc;
        RetrieveBookingsByUtc.FlightNumber = RetrieveBookingsByUtc.FlightNumber ?
            RetrieveBookingsByUtc.FlightNumber.padStart(4, ' ') : null;
        return this.RetrieveBookings({ RetrieveBookingsByUtc });
    }
    RetrieveBookingsByDepartureDate(FindBookingByBookingDepartureDate) {
        return this.RetrieveBookings({ FindBookingByBookingDepartureDate });
    }
    RetrieveBookings(model) {
        return !model ? this.http.get(this.configService.RetrieveAgentBookings).toPromise() :
            this.http.post(this.configService.RetrieveAgentBookings, model).toPromise();
    }
}
FindMyBookingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FindMyBookingsService_Factory() { return new FindMyBookingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: FindMyBookingsService, providedIn: "root" });
