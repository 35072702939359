import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, skip, map, shareReplay } from 'rxjs/operators';
import { StarterCancelRebook } from './models/change-booking-model';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
export class BookingService {
    constructor(http, config, translateService) {
        this.http = http;
        this.config = config;
        this.translateService = translateService;
        this.bookingObs = new BehaviorSubject(null);
        this.flightsToChangeObs = new BehaviorSubject(null);
        this.memberPromotionObs = new BehaviorSubject(null);
    }
    /**
     * Refreshes the booking information
     * @param forced if [true] a http request will be made. If [false] the http request will be made only on the first call
     */
    refresh(forced = false) {
        // if not forced and already completed return current value
        if (!forced && this.bookingSubscription && this.bookingSubscription.closed) {
            return Promise.resolve(this.bookingObs.value);
        }
        // if forced - cancel previous request if any
        if (forced && this.bookingSubscription) {
            this.bookingSubscription.unsubscribe();
        }
        // if forced or no previous requests wered attempted do the request
        if (forced || !this.bookingSubscription) {
            this.bookingSubscription = this.http.get(this.config.Booking).subscribe(b => {
                this.booking = b;
                this.bookingObs.next(this.booking);
            });
        }
        // return the next value the comes up in the subscription
        return new Promise(resolve => this.bookingObs.pipe(skip(1), take(1)).subscribe(data => resolve(data)));
    }
    getBooking() {
        return this.booking;
    }
    getSnapshot() {
        return this.http.get(this.config.BookingSnapshot).toPromise();
    }
    createSnapshot() {
        return this.http.post(this.config.BookingSnapshot, {}).toPromise();
    }
    clearSnapshot() {
        return this.http.delete(this.config.BookingSnapshot, {}).toPromise();
    }
    unsubscribeBookingFromNotifications(pnr, email) {
        let queryParams = `?RecordLocator=${pnr}&EmailAddress=${email}`;
        return this.http.post(this.config.UnsubscribeBookingFromNotificationsUrl + queryParams, {}).toPromise();
    }
    addComment(pnr, comment, email, noDuplicates) {
        var queryParams = `?RecordLocator=${pnr}&Comment=${comment}&EmailAddress=${email}&NoDuplicates=${noDuplicates}`;
        return this.http.post(this.config.AddBookingCommentUrl + queryParams, {}).toPromise();
    }
    refundCashBooking(emailAddress) {
        return this.http.post(this.config.RefundCashUrl, {
            emailAddress: emailAddress
        }).toPromise();
    }
    setPassengerConsent(emailAddress) {
        return this.http.post(this.config.PassengerConsentUrl, {
            emailAddress: emailAddress
        }).toPromise();
    }
    savePassengers(passengers, infants) {
        const putItem = {
            passengers: {
                items: []
            }
        };
        passengers.forEach(p => {
            const currentPaxInfant = infants.find(i => i.attachedPassengerNumber === p.passengerNumber);
            if (p.travelDocument && Object.keys(p.travelDocument).length > 0) {
                p.travelDocument.names = [];
                p.travelDocuments.items = [];
                p.travelDocument.name = p.name;
                p.travelDocument.names.push(p.name);
                p.travelDocument.passengerNumber = p.passengerNumber;
                p.travelDocuments.items.push(p.travelDocument);
            }
            const typeInfo = Object.assign({}, p.typeInfo);
            delete typeInfo.paxType;
            if (p.address && Object.keys(p.address).length > 0) {
                p.address.typeCode = 'C';
                p.addresses.items = [];
                p.addresses.items.push(p.address);
            }
            putItem.passengers.items.push({
                name: p.name,
                info: p.info,
                typeInfo: typeInfo,
                travelDocuments: p.travelDocuments,
                addresses: p.addresses,
                infant: currentPaxInfant,
                hasInfant: currentPaxInfant != null
            });
        });
        return this.http.put(this.config.PassengersUrl, putItem).toPromise();
    }
    saveContact(contact) {
        const contactToSave = Object.assign({}, contact);
        delete contactToSave.person;
        contactToSave.cultureCode = this.translateService.culture;
        return this.http.put(this.config.ContactUrl, { 'contact': contactToSave }).toPromise();
    }
    saveLockFareData(passengers, infants, contactData, titleItems) {
        const contactToSave = Object.assign({}, contactData);
        delete contactToSave.person;
        contactToSave.cultureCode = this.translateService.culture;
        const putItem = {
            passengers: {
                items: []
            },
            contact: contactToSave
        };
        const newArray = [];
        passengers.forEach(p => {
            const name = Object.assign({}, p.name || {});
            const info = Object.assign({}, p.info || {});
            const typeInfo = Object.assign({}, p.typeInfo || {});
            const passengerNumber = p.passengerNumber;
            newArray.push({
                name: name,
                info: info,
                typeInfo: typeInfo,
                passengerNumber: passengerNumber
            });
        });
        newArray.forEach(p => {
            if (!p.name.first && !p.name.last) {
                p.name.first = this.generateRandomString();
                p.name.last = this.generateRandomString();
                p.name.title = titleItems[1].value;
            }
            const currentPaxInfant = infants.find(i => i.attachedPassengerNumber === p.passengerNumber);
            const infant = Object.assign({}, currentPaxInfant || {});
            if (currentPaxInfant && !currentPaxInfant.name.first && !currentPaxInfant.name.last) {
                const name = Object.assign({}, currentPaxInfant.name || {});
                name.first = this.generateRandomString();
                name.last = this.generateRandomString();
                infant.name = name;
            }
            putItem.passengers.items.push({
                name: p.name,
                info: p.info,
                typeInfo: p.typeInfo,
                infant: infant,
                hasInfant: currentPaxInfant != null
            });
        });
        return this.http.put(this.config.LockFareDetails, putItem).toPromise();
    }
    generateRandomString() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charactersLength = characters.length;
        const index = Math.floor(Math.random() * charactersLength);
        for (let i = 0; i < 3; i++) {
            result += characters.charAt(index);
        }
        result += ' ';
        for (let i = 0; i < 3; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    retrieveBooking(recordLocator, lastName, emailAddress, apiUrl) {
        const data = { id: recordLocator, lastName: lastName, email: emailAddress };
        return this.http.post(apiUrl, data, {}).toPromise();
    }
    changeFlight(recordLocator, newFlightModel, oldFlights, selectedJourneys) {
        let journeys = [];
        oldFlights.forEach(oldFlight => {
            let journey = new StarterCancelRebook();
            if (selectedJourneys.filter(j => j.sellKey == oldFlight.sellKey).length) {
                journey.FromJourney = oldFlight.sellKey;
                journey.Origin = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.destination : newFlightModel.origin;
                journey.Destination = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.origin : newFlightModel.destination;
                journey.DepartureDate = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.returnDate : newFlightModel.departureDate;
            }
            else {
                journey.Origin = oldFlight.segments[0].departureStation;
                journey.Destination = oldFlight.segments[oldFlight.segments.length - 1].arrivalStation;
                journey.DepartureDate = oldFlight.segments[0].sTD;
            }
            journeys.push(journey);
        });
        const data = {
            starterCancelRebook: {
                SelectedCancelRebookFlights: [journeys[0], journeys.length == 2 ? journeys[1] : {}]
            }
        };
        return this.http.post(this.config.ChangeFlight + '?rl=' + recordLocator, data).toPromise();
    }
    moveFlights(journeyCriteria) {
        const data = {
            move: {
                MoveFlights: journeyCriteria
            }
        };
        return this.http.post(this.config.MoveFlight, data).toPromise();
    }
    checkUserEligibilityForPromotion() {
        return this.http.get(this.config.CheckUserEligibilityAndUsePromotion)
            .toPromise()
            .then((data) => {
            return data;
        });
    }
    applyUserPromotionAfterLoggin(response) {
        const data = { r: response };
        return this.http.post(this.config.CheckUserEligibilityAndUsePromotion, data, {}).toPromise();
    }
    clearSession() {
        return this.http.post(this.config.ClearSession, {}).toPromise();
    }
    manageBookingPriceBreakdown() {
        return this.http.get(this.config.PriceBreakdown).toPromise();
    }
    loadItineraryPage() {
        return this.http.get(this.config.Itinerary).toPromise();
    }
    loadMemberPromotionActivationInfo() {
        return this.http.get(this.config.MemberPromotionActivationInfoUrl).pipe(map((data) => data.memberPromotionActivationInfo.isValid && !data.memberPromotionActivationInfo.isActivated ?
            data.memberPromotionActivationInfo : null)).toPromise();
    }
    subscribeToNewsletter(isFirstPassenger = false) {
        return this.http.post(this.config.NewsletterSubscriptionUrl, {
            newsletterSubscription: {
                isFirstPassenger: isFirstPassenger
            }
        }).toPromise();
    }
    getMember(skipCache = false) {
        skipCache = skipCache || !this.memberDetails;
        if (!this.memberDetails) {
            this.memberDetails = new Observable(observer => this.memberDetailsObserver = observer).pipe(shareReplay(1));
        }
        if (skipCache) {
            this.http.get(this.config.MemberDetails).subscribe(memberDetails => this.memberDetailsObserver.next(memberDetails));
        }
        return this.memberDetails;
    }
    getMemberDetails() {
        this.http.get(this.config.MemberDetails).subscribe(data => this.memberPromotionObs.next(data));
    }
    isB2CBooking() {
        let journeys = this.booking.convertedJourneys.journeys;
        return ((this.booking.bookingDetail.pointOfSale.organizationCode === 'SYSTEM' ||
            this.booking.bookingDetail.pointOfSale.organizationCode == "BAMEMBERS" ||
            (this.booking.bookingDetail.pointOfSale.organizationCode == "0B" &&
                journeys.every(j => j.segments && j.segments.every(s => s.createdAgentRoleCode.match("WWWM")))))
            &&
                (this.booking.bookingDetail.pointOfSale.agentCode === 'WebAnonymous' ||
                    this.booking.bookingDetail.pointOfSale.agentCode === 'ApiAnonymous' ||
                    journeys.every(j => j.segments && j.segments.every(s => s.createdAgentRoleCode.match("WWWM")))));
    }
}
tslib_1.__decorate([
    LocalStorage(),
    tslib_1.__metadata("design:type", String)
], BookingService.prototype, "recordLocator", void 0);
