import { Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { CheckinService } from './checkin.service';
import { BookingFlowService } from './booking-flow.service';
import { CheckinSelectionService } from './checkin-selection.service';
import { BookingSelectionService } from './booking-selection.service';
import { BookingStepsService, ApplicationFlowEnum, CheckinSteps } from './booking-steps.service';
import * as i0 from "@angular/core";
import * as i1 from "./booking-steps.service";
export class FlowManagerService {
    constructor(injector, bookingSteps) {
        this.injector = injector;
        this.bookingSteps = bookingSteps;
        this.flow = environment.flow;
    }
    get applicationFlowService() {
        switch (environment.flow) {
            case 0:
                return this.injector.get(CheckinService);
            case 1:
                return this.injector.get(BookingFlowService);
        }
    }
    get selectionService() {
        switch (environment.flow) {
            case 0:
                return this.injector.get(CheckinSelectionService);
            case 1:
                return this.injector.get(BookingSelectionService);
        }
    }
    beginBookingFlow() {
        environment.flow = 1;
        this.bookingSteps.setFlow(ApplicationFlowEnum.Booking);
        this.bookingSteps.goToStep(CheckinSteps.search);
    }
    beginCheckinFlow() {
        environment.flow = 0;
        this.bookingSteps.setFlow(ApplicationFlowEnum.Checkin);
        this.bookingSteps.goToStep(CheckinSteps.search);
    }
}
FlowManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FlowManagerService_Factory() { return new FlowManagerService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.BookingStepsService)); }, token: FlowManagerService, providedIn: "root" });
