import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import * as i0 from "@angular/core";
export class CheckInLuggageModel {
    constructor(smallBagsNo, bigBagsNo, totalBagsNo, passengerSsr, $changeSmall, $changeBig) {
        this.smallBagsNo = smallBagsNo;
        this.bigBagsNo = bigBagsNo;
        this.totalBagsNo = totalBagsNo;
        this.passengerSsr = passengerSsr;
        this.$changeSmall = $changeSmall;
        this.$changeBig = $changeBig;
    }
    get upperDisabled() {
        return this.totalBagsNo === 4;
    }
    getLowerDisabled(ssr) {
        return ssr.name === SMALL_BAG ? this.smallBagsNo === 0 : this.bigBagsNo === 0;
    }
}
const SMALL_BAG = 'SBAG';
const BIG_BAG = 'BAG';
export class CheckInLuggageService {
    constructor() {
        this.$unsubscribe = new Subject();
        this.passengersLuggageModel = new Map();
        this.$checkingLuggageChanged = new Subject();
    }
    init(id, passengers) {
        if (!passengers)
            return;
        //delete the map key for the received flight unique id
        this.passengersLuggageModel.delete(id);
        passengers.forEach(ssrModel => {
            const flightId = ssrModel.passenger.flightUniqueId;
            let luggageModel = [];
            if (!this.passengersLuggageModel.has(flightId)) {
                this.passengersLuggageModel.set(flightId, luggageModel);
            }
            else {
                luggageModel = this.passengersLuggageModel.get(flightId);
            }
            const model = ssrModel.ssrs.reduce((model, currentItem) => {
                if (currentItem.name === SMALL_BAG) {
                    model.smallBagsNo = currentItem.selectedQuantity;
                }
                else {
                    model.bigBagsNo = currentItem.selectedQuantity;
                }
                model.totalBagsNo += currentItem.selectedQuantity;
                return model;
            }, new CheckInLuggageModel(0, 0, 0, Object.assign({}, ssrModel), new Subject(), new Subject()));
            model.$changeBig
                .pipe(takeUntil(this.$unsubscribe), debounceTime(700)).subscribe(model => {
                this.$checkingLuggageChanged.next(model);
            });
            model.$changeSmall
                .pipe(takeUntil(this.$unsubscribe), debounceTime(700)).subscribe(model => {
                this.$checkingLuggageChanged.next(model);
            });
            luggageModel.push(model);
        });
    }
    ngOnDestroy() {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }
    getForFlightId(flightId) {
        return this.passengersLuggageModel.get(flightId);
    }
    decreaseBagsNumber(id, index, model, bagType) {
        if (model.totalBagsNo > 0) {
            if (bagType === BIG_BAG) {
                if (model.bigBagsNo === 0) {
                    return;
                }
                model.bigBagsNo--;
                model.$changeBig.next({ id, index, ssrCode: bagType, qty: model.bigBagsNo });
            }
            else {
                if (model.smallBagsNo === 0) {
                    return;
                }
                model.smallBagsNo--;
                model.$changeSmall.next({ id, index, ssrCode: bagType, qty: model.smallBagsNo });
            }
            model.totalBagsNo--;
        }
    }
    increaseBagsNumber(id, index, model, bagType) {
        if (model.totalBagsNo < 4) {
            if (bagType === BIG_BAG) {
                model.bigBagsNo++;
                model.$changeBig.next({ id, index, ssrCode: bagType, qty: model.bigBagsNo });
            }
            else {
                model.smallBagsNo++;
                model.$changeSmall.next({ id, index, ssrCode: bagType, qty: model.smallBagsNo });
            }
            model.totalBagsNo++;
        }
    }
}
CheckInLuggageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckInLuggageService_Factory() { return new CheckInLuggageService(); }, token: CheckInLuggageService, providedIn: "root" });
