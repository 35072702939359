import * as tslib_1 from "tslib";
import { SeatMapService } from './../../seat/seat-map.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { SsrBoxContentPriorityBagComponent } from './../ssr-box-content-priority-bag/ssr-box-content-priority-bag.component';
import { SsrBoxContentFastTrackComponent } from './../ssr-box-content-fast-track/ssr-box-content-fast-track.component';
import { OnInit, OnChanges, SimpleChanges, OnDestroy, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SsrBoxContentLuggageComponent } from '../ssr-box-content-luggage/ssr-box-content-luggage.component';
import { SsrBoxContentMealsComponent } from '../ssr-box-content-meals/ssr-box-content-meals.component';
import { SsrBoxContentPetsComponent } from '../ssr-box-content-pets/ssr-box-content-pets.component';
import { SsrBoxContentSpecialEquipmentComponent } from '../ssr-box-content-special-equipment/ssr-box-content-special-equipment.component';
// tslint:disable-next-line:max-line-length
import { SsrBoxContentSpecialAssistanceComponent } from '../ssr-box-content-special-assistance/ssr-box-content-special-assistance.component';
import { ConfigService } from '../../core/config.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { SsrBoxContentPriorityBoardingComponent } from '../ssr-box-content-priority-boarding/ssr-box-content-priority-boarding.component';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { SsrBoxContentLoungeComponent } from '../ssr-box-content-lounge/ssr-box-content-lounge.component';
// tslint:disable-next-line: max-line-length
import { SsrsSeatsRemovalNotificationComponent } from '../../shared/ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { SsrSellingTypeHelper } from '../ssr-selling-type.enum';
import { environment } from '../../../environments/environment';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrBoxContentAirportCheckinComponent } from '../ssr-box-content-airport-checkin/ssr-box-content-airport-checkin.component';
import { ProfileService } from 'src/app/core/profile.service';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { isArray } from 'util';
import { SsrBoxContentFlexComponent } from '../ssr-box-content-flex/ssr-box-content-flex.component';
import * as moment from 'moment';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
import { SsrBoxContentEarlyCheckinComponent } from '../ssr-box-content-early-checkin/ssr-box-content-early-checkin.component';
import { SsrBoxContentBusTransfer } from '../ssr-box-content-bus-transfer/ssr-box-content-bus-transfer.component';
export class SsrBoxComponent {
    constructor(ssrsService, flowManager, config, loadingSpinnerService, translateService, elRef, seatmapService, ecommerce, profileService, syncService, resourcesService) {
        this.ssrsService = ssrsService;
        this.flowManager = flowManager;
        this.config = config;
        this.loadingSpinnerService = loadingSpinnerService;
        this.translateService = translateService;
        this.elRef = elRef;
        this.seatmapService = seatmapService;
        this.ecommerce = ecommerce;
        this.profileService = profileService;
        this.syncService = syncService;
        this.resourcesService = resourcesService;
        this.flightsObs = new BehaviorSubject(null);
        this.currentFlights = null;
        this.SsrTypeEnum = SsrType;
        this.isExpanded = false;
        this.isModified = false;
        this.pdfLanguage = 'en';
        this.enableSameOptionsForAllFlights = false;
        this.ssrBoxContentComponents = [];
        this.promoDiscount = null;
        this.minHoursToPurchaseBeforeFlight = null;
        this.ssrsAvailabilityByFlight = {};
        this.afterSaveChanges = [];
        this.assetsPath = environment.assetsPath;
        this.shoppingCartExtrasSubscription = this.flowManager.applicationFlowService.shoppingCartBreakdown.pipe(filter(b => b && b.currentShoppingCart ? true : false))
            .subscribe(b => {
            this.shoppingCartExtras = b.currentShoppingCart.extras;
            if (!this.isExpanded) {
                this.updateTotalPrice(true);
            }
        });
        this.reloadSsrSubscription = this.ssrsService.reloadingSsrsObs.pipe(filter(loadingInfo => loadingInfo.ssrType === this.ssrType))
            .subscribe(loadingInfo => {
            if (loadingInfo.isLoading) {
                this.loadingSpinnerService.showSpinnerById(this.spinnerId);
            }
            else {
                this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
            }
        });
        if (this.translateService.culture) {
            this.pdfLanguage = this.translateService.culture.toLowerCase().includes('ro') ? 'ro' : 'en';
        }
        this.enableFlightsUpdates();
    }
    set flights(value) {
        this.flightsObs.next(value);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.minHoursToPurchaseBeforeFlight = yield this.resourcesService.getMinHoursToPurchaseBeforeFlightAsync();
        });
    }
    ngOnDestroy() {
        if (this.shoppingCartExtrasSubscription) {
            this.shoppingCartExtrasSubscription.unsubscribe();
        }
        if (this.availableSsrsSubscription) {
            this.availableSsrsSubscription.unsubscribe();
        }
        if (this.reloadSsrSubscription) {
            this.reloadSsrSubscription.unsubscribe();
        }
        if (this.flightsSub) {
            this.flightsSub.unsubscribe();
        }
    }
    ngAfterViewInit() {
        let components;
        switch (this.ssrType) {
            case SsrType.Baggage:
                components = this.luggageComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                this.afterSaveChanges.push(() => {
                    this.ssrsService.toggleLoadingForSsrType(true, SsrType.PriorityBag);
                    const showPriorityPopup = this.ssrsService.updatePriorityBag(this.currentFlights);
                    if (showPriorityPopup) {
                        this.pbagModal.openPopup(() => { });
                        return this.ssrsService.applySsrs(SsrType.PriorityBag);
                    }
                    return Promise.resolve();
                });
                this.afterSaveChanges.push(() => this.ssrsService.getSsrsInfo().toPromise());
                this.afterSaveChanges.push(() => this.ssrsService.toggleLoadingForSsrType(false, SsrType.PriorityBag));
                break;
            case SsrType.Meal:
                components = this.mealComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(avSsrs => {
                    if (avSsrs) {
                        this.setLowestPrice(this.ssrType);
                    }
                });
                break;
            case SsrType.Lounge:
                components = this.loungeComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.Pet:
                components = this.petComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.SpecialEquipment:
                components = this.specialEquipmentComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.SpecialAssistance:
                components = this.specialAssistanceComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                // this.getSeatsWithoutSsrRestriction();
                break;
            case SsrType.PriorityBoarding:
                components = this.priorityBoardingComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.PriorityBag:
                components = this.priorityBagComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(avSsrs => {
                    if (avSsrs) {
                        this.cancelChanges();
                        this.updateSsrAvailabilityByFlight();
                        this.setLowestPrice(this.ssrType);
                    }
                });
                break;
            case SsrType.FastTrack:
                components = this.fastTrackComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.AirportCheckin:
                components = this.airportCheckinComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
            case SsrType.EarlyCheckin:
                components = this.earlyCheckinComponents.changes.subscribe(r => this.setReturnFlightComponent(r));
                break;
            case SsrType.BusTransfer:
                components = this.busTransferComponents.changes.subscribe(r => this.setReturnFlightComponent(r));
                break;
            case SsrType.Flex:
                components = this.flexComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
                break;
        }
        this.expandBox();
    }
    setLowestPrice(ssrType) {
        const ssrsList = this.ssrsService.availableSsrs.find(lists => lists.key === ssrType);
        if (ssrsList) {
            const ssrsArray = ssrsList.value.map(s => s.value).reduce((a, b) => a.concat(b), []);
            const prices = ssrsArray.map(s => s.price);
            this.lowestPrice = Math.min(...prices);
            this.ssrCodesList = ssrsArray.map(s => s.ssrCode);
            if (this.ssrsService.memberPromotions.ssrSegmentPromotionList.length > 0) {
                const originalPrices = [];
                ssrsArray.forEach(ssr => {
                    if (ssr.originalPrice) {
                        originalPrices.push(ssr.originalPrice);
                        this.hasPromotion = true;
                    }
                    else {
                        originalPrices.push(ssr.price);
                    }
                });
                if (this.hasPromotion) {
                    this.lowestOriginalPrice = Math.min(...originalPrices);
                    this.hasPromotion = this.lowestOriginalPrice !== this.lowestPrice;
                }
            }
        }
    }
    setReturnFlightComponent(components) {
        setTimeout(() => {
            this.ssrBoxContentComponents = components ? components.toArray() : [];
        });
    }
    ngOnChanges(changes) {
        if (changes.ssrType) {
            this.setLowestPrice(changes.ssrType.currentValue);
            this.ssrsService.resetSsrs(this.ssrType);
            this.updateSsrAvailabilityByFlight();
            this.spinnerId = 'extras-spinner' + this.ssrType;
            this.promoDiscount = this.ssrsService.getPromoDiscount(this.ssrType);
        }
    }
    expandBox() {
        if (this.flightsSub) {
            this.flightsSub.unsubscribe();
        }
        this.isExpanded = true;
        const ecommerceCartItems = [];
        const item = new ECommerceCartItem(SsrType[this.ssrType], this.ecommerce.getFlightInfo(0), '0', 'BlueAir', SsrType[this.ssrType] + ' click', SsrType[this.ssrType], 1, this.ssrsService.actualDisplayOrder.indexOf(this.ssrType) + 1, null, this.ecommerce.getUserData())
            .getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
    }
    enableFlightsUpdates() {
        if (!this.flightsSub || this.flightsSub.closed) {
            this.flightsSub = this.flightsObs.subscribe(newFlights => {
                this.currentFlights = newFlights;
                try {
                    if (this.syncService && isArray(newFlights)) {
                        this.syncService.flights = [...this.currentFlights];
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                }
                this.updateSsrAvailabilityByFlight();
                this.updateTotalPrice(true);
                this.enableSameOptionsForAllFlights =
                    this.currentFlights && this.currentFlights.length === 2 &&
                        this.currentFlights[0].departureStation.iataCode === this.currentFlights[1].arrivalStation.iataCode &&
                        this.currentFlights[0].arrivalStation.iataCode === this.currentFlights[1].departureStation.iataCode;
            });
        }
    }
    saveChanges(params) {
        this.ssrsService.resetSsrs(this.ssrType);
        const newSsrs = [];
        this.ssrBoxContentComponents.forEach(c => {
            newSsrs.push(...c.applySsrs());
        });
        const seatsToBeRemoved = this.ssrsService.getSeatsToBeRemoved(this.ssrType, this.currentFlights, newSsrs);
        if (!seatsToBeRemoved.length) {
            this.completeSaveChanges();
        }
        else {
            this.seatRemovalModal.openPopup((userAgreed) => {
                if (userAgreed) {
                    this.loadingSpinnerService.showSpinnerById(this.spinnerId);
                    this.seatmapService.removeSeats(seatsToBeRemoved).then(() => this.completeSaveChanges());
                }
                else {
                    if (params && params.onCancelSeatRemoval) {
                        params.onCancelSeatRemoval();
                    }
                }
            }, seatsToBeRemoved);
        }
    }
    completeSaveChanges() {
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.ssrsService.applySsrs(this.ssrType)
            .then(() => this.enableFlightsUpdates())
            .then(() => {
            // chain all after save changes promises
            return this.afterSaveChanges.reduce((chain, current) => chain.then(current), Promise.resolve());
        })
            .then(() => {
            // this.closeBox();
            this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
        });
    }
    cancelChanges() {
        this.ssrsService.resetSsrs(this.ssrType);
        this.enableFlightsUpdates();
        this.closeBox();
    }
    closeBox() {
        this.isExpanded = false;
    }
    _isPassengersArray(passenger) {
        if (Array.isArray(passenger)) {
            return true;
        }
        return false;
    }
    _removeFromCartForOnePassenger(flight, passenger) {
        for (let passengerNumber in flight.ssrs) {
            if (!(passenger && passenger.passengerNumber.toString() !== passengerNumber)) {
                if (flight.ssrs.hasOwnProperty(passengerNumber)) {
                    const passengerSsrs = flight.ssrs[passengerNumber].filter(s => this.ssrCodesList.indexOf(s) >= 0);
                    const paidSsrs = flight.paidSsrs[passengerNumber].filter(s => this.ssrCodesList.indexOf(s) >= 0);
                    const ssrGroupBy = passengerSsrs.reduce((group, ssr) => {
                        (group[ssr] = group[ssr] || []).push(ssr);
                        return group;
                    }, {});
                    for (let ssrCode in ssrGroupBy) {
                        if (ssrGroupBy.hasOwnProperty(ssrCode)) {
                            let count = ssrGroupBy[ssrCode].length;
                            while (count > 0) {
                                const paidSsrsCount = paidSsrs.filter(ssr => ssr === ssrCode).length;
                                if (paidSsrsCount > 0) {
                                    this.ssrsService.removeSsr(this.ssrType, ssrCode, count + paidSsrsCount, +passengerNumber, flight, true);
                                }
                                else {
                                    this.ssrsService.removeSsr(this.ssrType, ssrCode, count, +passengerNumber, flight, true);
                                }
                                count--;
                            }
                        }
                    }
                }
            }
        }
    }
    removeFromCart(passengers) {
        passengers = passengers || [];
        this.currentFlights.forEach(f => {
            if (passengers.length === 0) {
                this._removeFromCartForOnePassenger(f);
            }
            else {
                passengers.forEach(p => {
                    if (p.flightUniqueId === f.flightUniqueId) {
                        this._removeFromCartForOnePassenger(f, p);
                    }
                });
            }
        });
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.ssrsService.applySsrs(this.ssrType)
            .then(() => this.enableFlightsUpdates())
            .then(() => {
            return this.afterSaveChanges.reduce((chain, current) => chain.then(current), Promise.resolve());
        })
            .then(() => {
            this.flightPrices.forEach((f, i) => {
                f.fill(0);
            });
            this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
        });
    }
    getIsModified() {
        switch (this.ssrType) {
            case SsrType.SpecialAssistance:
            case SsrType.Meal:
            case SsrType.Baggage:
            case SsrType.SpecialEquipment: {
                return this.areSsrsSelected();
            }
            default: {
                return this.totalPrice > 0;
            }
        }
    }
    areSsrsSelected() {
        return this.shoppingCartExtras.some(x => x.ssrType === this.ssrType && x.flightsWithItems.length > 0);
    }
    updateTotalPrice(forced = false) {
        if (!this.shoppingCartExtras || !this.ssrType) {
            return;
        }
        const shoppingCartItem = this.shoppingCartExtras.find(e => e.ssrType === this.ssrType);
        this.totalPrice = shoppingCartItem ? shoppingCartItem.amount : 0;
        this.isModified = this.getIsModified();
        this.temporaryTotalPrice = this.totalPrice;
        if (this.currentFlights && (!this.flightPrices || forced)) {
            this.flightPrices = this.currentFlights.map(f => {
                let prices = [];
                prices.length = f.segments.length;
                prices.fill(0);
                if (shoppingCartItem) {
                    const shoppingCartSegments = shoppingCartItem.flights
                        .filter(sf => sf.departureStation === f.departureStation.iataCode && sf.arrivalStation === f.arrivalStation.iataCode)
                        .reduce((segments, sf) => segments.concat(sf.segments), []);
                    f.segments.forEach((seg, i) => {
                        prices[i] = shoppingCartSegments
                            .filter(ss => ss.departureStation === seg.departureStation.iataCode && ss.arrivalStation === seg.arrivalStation.iataCode)
                            .sum(ss => ss.amount);
                    });
                    if (SsrSellingTypeHelper.IsSsrTypeSoldByJourney(this.ssrType)) {
                        prices = [prices.sum(p => p)];
                    }
                }
                return prices;
            });
        }
    }
    updateTotalPricePerFlight(price, flightIndex, segmentIndex = 0) {
        if (!this.flightPrices) {
            this.flightPrices = this.currentFlights.map(f => {
                return f.segments.map(s => 0);
            });
        }
        this.flightPrices[flightIndex][segmentIndex] = price;
        this.temporaryTotalPrice = this.flightPrices
            .reduce((a, b) => a.concat(b), [])
            .reduce((a, b) => a + b);
    }
    updateSsrAvailabilityByFlight() {
        if (!this.currentFlights || !this.ssrType) {
            return;
        }
        this.ssrsAvailabilityByFlight = {};
        const ssrList = this.ssrsService.availableSsrs.find(lists => lists.key === this.ssrType);
        if (ssrList && ssrList.value && ssrList.value.length > 0) {
            const allSegments = this.currentFlights.map(f => f.segments).reduce((a, b) => a.concat(b), []);
            this.currentFlights.concat(allSegments).forEach(segOrFlight => {
                const flightKey = `${segOrFlight.departureStation.iataCode}_${segOrFlight.arrivalStation.iataCode}`;
                const ssrs = ssrList.value.find(s => s.key === flightKey);
                this.ssrsAvailabilityByFlight[flightKey] = ssrs && ssrs.value && ssrs.value.some(i => i.limitPerPassenger.some(p => p.value > 0));
            });
        }
    }
    isAvailableForFlight(flight) {
        if (this.ssrType == this.SsrTypeEnum.FastTrack) {
            let segmentsNotAvailable = 0;
            flight.segments.forEach(segment => {
                const flightKey = `${segment.departureStation.iataCode}_${segment.arrivalStation.iataCode}`;
                if (!this.ssrsAvailabilityByFlight[flightKey]) {
                    segmentsNotAvailable++;
                }
                ;
            });
            if (segmentsNotAvailable == flight.segments.length) {
                return false;
            }
            return true;
        }
        const flightKey = `${flight.departureStation.iataCode}_${flight.arrivalStation.iataCode}`;
        return this.ssrsAvailabilityByFlight[flightKey] && this.ssrsAvailabilityByFlight[flightKey] === true;
    }
    isAvailableForJourney(flights) {
        if (flights.map(f => this.isAvailableForFlight(f)).every((v) => v == false)) {
            return false;
        }
        return true;
    }
    applySsrs() {
        this.ssrsService.resetSsrs(this.ssrType);
        this.ssrBoxContentComponents.forEach(c => {
            c.applySsrs();
        });
    }
    /**
     *
     * @param sellDefaultSsr if TRUE, user has decided to buy default meal ssr
     * else user wants to add meal ssr for each passenger
     */
    validationModalAction(sellDefaultSsr) {
        if (!sellDefaultSsr) {
            this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
            //this.expandBox();
        }
    }
    isFlexAvailable(flight) {
        if (this.currentFlights.every(f => f.paidSsrs[0] && f.paidSsrs[0].some(s => s === "FLX"))) {
            return true;
        }
        let date = moment(flight.departureStation.dateUtc);
        const currentDate = moment(new Date());
        let tempHours = date.diff(currentDate, 'hours');
        return tempHours >= this.minHoursToPurchaseBeforeFlight ? true : false;
    }
    showLuggageErrors() {
        this.luggageComponents.forEach(c => {
            c.passengers.forEach(p => {
                if (!c.ssrsService.luggagePassengersMap.get(c.flight.departureStation.iataCode + c.flight.arrivalStation.iataCode)) {
                    c.scrollTo();
                }
            });
        });
    }
}
