import { ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import * as i0 from "@angular/core";
export class BlueDomService {
    constructor(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    appendComponentToElement(component, element) {
        // 1. Create a component reference from the component 
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);
        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
        // 3. Get DOM element from component
        const domElem = componentRef.hostView
            .rootNodes[0];
        // 4. Append DOM element to the body
        element.appendChild(domElem);
        // // 5. Wait some time and remove it from the component tree and from the DOM
        // setTimeout(() => {
        //     this.appRef.detachView(componentRef.hostView);
        //     componentRef.destroy();
        // }, 3000);
    }
}
BlueDomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlueDomService_Factory() { return new BlueDomService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: BlueDomService, providedIn: "root" });
