import * as tslib_1 from "tslib";
import { DefaultModalComponent } from './../default-modal/default-modal.component';
import { SsrsService } from './../../extras/ssrs.service';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { JourneyBundle } from './../../core/models/bundle.model';
import { BundleService } from './../bundle.service';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SsrType } from '../../extras/ssr-type.enum';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { BookingService } from '../../core/booking.service';
import { Constants } from '../../constants';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { ECommerceService } from '../e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../e-commerce/models/e-commerce-cart-item.model';
export class PassengerBundleComponent {
    constructor(bundleService, flowManager, loadingSpinnerService, ssrsService, bookingStepsService, bookingService, ecommerce, translateService) {
        this.bundleService = bundleService;
        this.flowManager = flowManager;
        this.loadingSpinnerService = loadingSpinnerService;
        this.ssrsService = ssrsService;
        this.bookingStepsService = bookingStepsService;
        this.bookingService = bookingService;
        this.ecommerce = ecommerce;
        this.translateService = translateService;
        this.bundleChanged = new EventEmitter();
        this.selectedClassName = 'selected';
        this.threeColumnClassName = 'threeColumnBundle';
        this.addOnBundleType = 'AddOn';
        this.temporaryTotalPrice = 0;
        this.spinnerId = 'bundle-spinner';
        this.journeyBundles = [];
        this.currentBundlesSelected = [];
        this.currentPricesSelected = [];
        this.hasCheckedInPax = false;
        this.flights = [];
        this.bannerBundleSsrList = [];
        this.upgradedFlightsList = [];
        this.boughtBundles = [];
        this.visible = false;
        this.bundleForBothInfoModalId = 'bundleForBothInfoModal';
    }
    ngOnInit() {
        this.loadData();
        this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(result => {
            if (result) {
                const checkedInPaxJourneys = result.assignedSeats.journeys.items.map(x => x.segments.items
                    .reduce((a, b) => a.concat(b), [])
                    .map(y => y.passengerSegments.items)
                    .reduce((a, b) => a.concat(b), [])
                    .filter(z => z.liftStatus !== Constants.NotCheckedIn)
                    .reduce((a, b) => a.concat(b), [])
                    .map(t => t.passengerNumber));
                if (checkedInPaxJourneys.some(x => x.length)) {
                    this.hasCheckedInPax = true;
                }
            }
        });
        this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(flights => {
            if (flights) {
                this.flights = flights;
            }
        });
    }
    ngOnDestroy() {
        if (this.flightsSubscription) {
            this.flightsSubscription.unsubscribe();
        }
        if (this.bookingServiceSubscription) {
            this.bookingServiceSubscription.unsubscribe();
        }
    }
    loadData() {
        this.journeyBundles = [];
        this.bundleService.refreshBundleDetails().then(data => {
            this.bundleDetails = data;
            this.bundleDetails.passengerJourneyrBundles.journeyBundles.forEach(jb => {
                this.journeyBundles.push(new JourneyBundle(jb));
            });
            this.paxCount = this.bundleDetails.passengerJourneyrBundles.passengers.items.length;
            this.bundleDetails.passengerJourneyrBundles.currentBundleCodes.forEach((bundleCode, journeyIndex) => {
                if (bundleCode[0]) {
                    const selectedBundle = this.journeyBundles[journeyIndex].bundleList.find(b => b.serviceBundleCode === bundleCode[0].value);
                    if (selectedBundle) {
                        this.selectBundle(this.journeyBundles[journeyIndex].bundleList.indexOf(selectedBundle), journeyIndex, selectedBundle.price, true);
                    }
                }
            });
            this.setMinUpgradeBundle();
            this.checkUpgradedBundle();
            const eCommerceCartItems = new Array();
            const item = new ECommerceCartItem('Bundle upgrade', this.ecommerce.getFlightInfo(0), '0', 'BlueAir', 'Bundle loaded', this.ecommerce.getFlightType(), this.ecommerce.getPassengersCount(), 0, null, this.ecommerce.getUserData()).getItem(true);
            eCommerceCartItems.push(item);
            this.ecommerce.ProductViews(eCommerceCartItems);
        });
    }
    isBundleUpgradeAvailable(journeyIndex) {
        return this.journeyBundles[journeyIndex] ? this.journeyBundles[journeyIndex].bundleList.length > 1 : false;
    }
    selectBundle(bundleIndex, journeyIndex, bundlePrice, fromInit = false) {
        this.currentBundlesSelected[journeyIndex] = bundleIndex;
        this.currentPricesSelected[journeyIndex] = bundlePrice;
        if (this.flights && this.flights.length > 1) {
            this.currentBundlesSelected[1] = bundleIndex;
            this.currentPricesSelected[1] = bundlePrice;
        }
        this.setSelectedStatus(journeyIndex, bundleIndex);
        this.setTemporaryPrice();
        if (!fromInit) {
            const ecommerceCartItems = [];
            const item = new ECommerceCartItem('Bundle upgrade', this.ecommerce.getFlightInfo(journeyIndex), bundlePrice.toString(), 'BlueAir', 'Bundle selected', this.ecommerce.getFlightType(), this.ecommerce.getPassengersCount(), 0, null, this.ecommerce.getUserData()).getItem(true);
            ecommerceCartItems.push(item);
            this.ecommerce.ProductClicks(ecommerceCartItems);
        }
    }
    setSelectedStatus(journeyIndex, bundleIndex) {
        this.journeyBundles[journeyIndex].bundleList.forEach((bundle, index) => {
            bundle.isSelected = index === bundleIndex ? true : false;
        });
    }
    setTemporaryPrice() {
        this.temporaryTotalPrice = this.currentPricesSelected.reduce((a, b) => a + b, 0);
    }
    _removeExtraSsr(flightsSSRsInBundle) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const groupedBySsrCode = {};
            for (const flight of flightsSSRsInBundle) {
                for (const ssr of flight.ssrs) {
                    if (!groupedBySsrCode[ssr.ssrCode]) {
                        groupedBySsrCode[ssr.ssrCode] = [];
                    }
                    groupedBySsrCode[ssr.ssrCode].push(ssr);
                }
            }
            for (const ssrCode in groupedBySsrCode) {
                let totalSsrsRemoved = 0;
                for (const ssr of groupedBySsrCode[ssrCode]) {
                    const ssrNumbersToRemove = ssr.getSsrNumbersToRemove();
                    ssrNumbersToRemove.forEach(ssrNumber => {
                        this.ssrsService.removeSsr(ssr.ssrType, ssr.ssrCode, ssrNumber, ssr.passengerNumber, ssr.segment, false);
                    });
                    totalSsrsRemoved += ssrNumbersToRemove.length;
                }
                if (totalSsrsRemoved > 0) {
                    yield this.ssrsService.applySsrs(groupedBySsrCode[ssrCode][0].ssrType);
                }
            }
        });
    }
    sellBundles(isDowngrade) {
        this.bundleForBothInfoModal.cancel();
        const flightsSSRsInBundle = this.currentBundlesSelected.map((bundleIndex, flightIndex) => {
            return new FlightSSRsInBundle(flightIndex, this.flights[flightIndex], this.journeyBundles[flightIndex].bundleList[bundleIndex], this.ssrsService, this.bookingService);
        });
        if (!this.hasCheckedInPax) {
            const data = { passengerJourneyrBundles: { selectedCodes: [] } };
            let paxCount = this.bundleDetails.passengerJourneyrBundles.passengers.items.length;
            const ecommerceCartItems = new Array();
            if (isDowngrade) {
                // if passenger deletes all bundle upgrades, select Included bundle (bundle with index 0) for each journey
                this.bundleDetails.passengerJourneyrBundles.currentBundleCodes.forEach((bundleCode, journeyIndex) => {
                    if (bundleCode[0]) {
                        this.selectBundle(0, journeyIndex, 0, true);
                    }
                });
            }
            this.currentBundlesSelected.forEach((bundleIndex, journeyIndex) => {
                let bundleToSell = this.journeyBundles.find(jb => jb.journeyIndex == journeyIndex).bundleList[bundleIndex];
                for (let paxNr = 0; paxNr < paxCount; paxNr++) {
                    data.passengerJourneyrBundles.selectedCodes.push(bundleToSell.serviceBundleCode.concat('|', journeyIndex.toString(), '|', paxNr.toString()));
                }
            });
            const onExtrasPage = this.bookingStepsService.currentStep.value === CheckinSteps.extras;
            const onSeatsPage = this.bookingStepsService.currentStep.value === CheckinSteps.seat;
            const applicationFlowService = this.flowManager.applicationFlowService;
            this.loadingSpinnerService.showSpinnerById(this.spinnerId);
            const sellBundleFunc = () => {
                return this.bundleService.sellBundle(data)
                    .then(() => {
                    this.currentPricesSelected = isDowngrade ? [0, 0] : this.currentPricesSelected;
                    this.setTemporaryPrice();
                    if (!isDowngrade) {
                        this.toggleExpanded(false);
                    }
                })
                    .then(() => onExtrasPage && this.ssrsService.toggleLoadingForSsrType(true, SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal))
                    .then(() => this.bundleService.refreshBundleDetails())
                    .then(() => applicationFlowService.loadFlowInfo(true))
                    .then(() => {
                    this.setMinUpgradeBundle();
                    this.checkUpgradedBundle();
                })
                    .then(() => {
                    this.currentBundlesSelected.forEach((bundleIndex, journeyIndex) => {
                        const item = new ECommerceCartItem('Bundle upgrade', this.ecommerce.getFlightInfo(journeyIndex), this.currentPricesSelected[journeyIndex].toString(), 'BlueAir', this.upgradedFlightsList[journeyIndex] ?
                            (this.translateService.instant(this.upgradedFlightsList[journeyIndex].initialBundle, 'bundle.name') + ' to ' +
                                this.translateService.instant(this.upgradedFlightsList[journeyIndex].selectedBundle, 'bundle.name')) :
                            this.translateService.instant(this.bundleDetails.passengerJourneyrBundles.journeyBundles[journeyIndex].items[0].serviceBundleCode, 'bundle.name'), this.ecommerce.getFlightType(), this.ecommerce.getPassengersCount(), 0, null, this.ecommerce.getUserData()).getItem(true);
                        ecommerceCartItems.push(item);
                    });
                    if (!isDowngrade) {
                        this.ecommerce.AddToCart(ecommerceCartItems);
                    }
                    else {
                        this.ecommerce.RemoveFromCart(ecommerceCartItems);
                    }
                })
                    .then(() => {
                    if (isDowngrade && this.ssrsService.updatePriorityBag(this.flights)) {
                        if (this.pbagModal) {
                            this.pbagModal.openPopup(() => {
                            });
                        }
                        return this.ssrsService.applySsrs(SsrType.PriorityBag);
                    }
                })
                    .then(() => {
                    if (onSeatsPage) {
                        this.bundleChanged.next();
                    }
                })
                    .then(() => Promise.all([
                    this.ssrsService.getSsrsInfo().toPromise(),
                    applicationFlowService.loadPriceBreakdown(true)
                ]))
                    .then(() => this.loadingSpinnerService.hideSpinnerById(this.spinnerId))
                    .then(() => onExtrasPage && this.ssrsService.toggleLoadingForSsrType(false, SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal, SsrType.PriorityBoarding));
            };
            this._removeExtraSsr(flightsSSRsInBundle).then(() => {
                return sellBundleFunc().then(() => window.location.reload());
            });
        }
    }
    confirmBundle(isDowngrade) {
        if (this.journeyBundles.length > 1) {
            this.togglebundleForBothInfoModalId();
        }
        else {
            this.sellBundles(isDowngrade);
        }
    }
    toggleExpanded(isExpanded = !this.isExpanded) {
        this.isExpanded = isExpanded;
        const ecommerceCartItems = new Array();
        const item = new ECommerceCartItem('Bundle upgrade', 'View bundle upgrade', '0', 'BlueAir', 'Bundle upgrade', '1', 1, 0, null, this.ecommerce.getUserData()).getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
    }
    checkUpgradedBundle() {
        if (this.journeyBundles && this.journeyBundles.length && this.flights) {
            this.upgradedFlightsList = [];
            this.flights.forEach((f, journeyIndex) => {
                const selectedBundle = this.journeyBundles[journeyIndex].bundleList.find(jb => jb.isSelected);
                const includedBundle = this.journeyBundles[journeyIndex].bundleList.find(jb => jb.price === 0);
                if (selectedBundle && includedBundle) {
                    if (selectedBundle.serviceBundleCode !== includedBundle.serviceBundleCode) {
                        const bundleUpgrade = new BundleUpgrade(f.departureStation.iataCode, f.arrivalStation.iataCode, includedBundle.serviceBundleCode, selectedBundle.serviceBundleCode);
                        this.upgradedFlightsList.push(bundleUpgrade);
                    }
                }
            });
        }
    }
    setMinUpgradeBundle() {
        this.boughtBundles = [];
        if (this.journeyBundles && this.journeyBundles.length) {
            const allBundles = this.journeyBundles.map(jb => jb.bundleList).reduce((a, b) => a.concat(b), []);
            const addOnBundles = [];
            this.journeyBundles.forEach(j => {
                const selectedBundle = j.bundleList.find(jb => jb.isSelected);
                this.boughtBundles.push(selectedBundle);
                if (j.bundleList.length > 1) {
                    this.visible = true;
                }
                j.bundleList.forEach(bl => {
                    if (bl.price > (selectedBundle ? selectedBundle.price : 0) && !bl.isSelected && bl.serviceBundleType === this.addOnBundleType) {
                        addOnBundles.push(bl);
                    }
                });
            });
            if (addOnBundles.length > 0) {
                const lowestUpgradeBundle = addOnBundles.reduce((a, b) => a.price < b.price ? a : b);
                this.startingFromPrice = lowestUpgradeBundle.price.toFixed(2);
                const index = allBundles.findIndex(x => x.serviceBundleCode === lowestUpgradeBundle.serviceBundleCode && !x.isSelected);
                if (index > -1) {
                    const beforeUpgradeSsrs = index > 0 ?
                        allBundles[index - 1].serviceBundleSsrs.reduce((a, b) => a.concat(b), []) :
                        allBundles[0].serviceBundleSsrs.reduce((a, b) => a.concat(b), []);
                    const differences = lowestUpgradeBundle.serviceBundleSsrs.filter(x => !beforeUpgradeSsrs.some(y => y.ssrCode === x.ssrCode));
                    this.bannerBundleSsrList = differences.map(x => ({ bundleCode: lowestUpgradeBundle.serviceBundleCode, ssrCode: x.ssrCode }));
                }
            }
            else {
                this.bannerBundleSsrList = [];
            }
        }
    }
    getCustomClasses(bundleIndex, journeyIndex) {
        let selectionClass = this.currentBundlesSelected[journeyIndex] == bundleIndex ? this.selectedClassName : '';
        let threeColumnClass = this.journeyBundles[journeyIndex].bundleList.length % 3 == 0 ? this.threeColumnClassName : '';
        return selectionClass.concat(' ', threeColumnClass);
    }
    cancelChanges() {
        this.loadData();
        this.toggleExpanded(false);
    }
    checkPendingBundle() {
        return this.isExpanded && this.boughtBundles.some(x => !x.isSelected);
    }
    togglebundleForBothInfoModalId() {
        this.bundleForBothInfoModal.openPopup(this.bundleForBothInfoModalId);
    }
    closebundleForBothInfoModal() {
        this.bundleForBothInfoModal.cancel();
    }
}
export class BundleUpgrade {
    constructor(departure, arrival, initialBundle, selectedBundle) {
        this.departureStation = departure;
        this.arrivalStation = arrival;
        this.initialBundle = initialBundle;
        this.selectedBundle = selectedBundle;
    }
}
class SSRInBundlePerSegmentAndPassenger {
    constructor(passengerNumber, ssrCode, ssrType, segment, bookedSsrs, maxQuantityPerPassenger, includedQuantityInBundle = 1) {
        this.passengerNumber = passengerNumber;
        this.ssrCode = ssrCode;
        this.ssrType = ssrType;
        this.segment = segment;
        this.bookedSsrs = bookedSsrs;
        this.maxQuantityPerPassenger = maxQuantityPerPassenger;
        this.includedQuantityInBundle = includedQuantityInBundle;
    }
    getSsrNumbersToRemove() {
        const ssrsToRemove = [];
        const quantityToRemove = (this.bookedSsrs.length + this.includedQuantityInBundle) - this.maxQuantityPerPassenger;
        for (let i = this.bookedSsrs.length - 1; i >= this.bookedSsrs.length - quantityToRemove; i--) {
            ssrsToRemove.push(this.bookedSsrs[i]);
        }
        return ssrsToRemove;
    }
}
class FlightSSRsInBundle {
    constructor(flightIndex, flight, selectedBundle, ssrService, bookingService) {
        this.flightIndex = flightIndex;
        this.flight = flight;
        this.selectedBundle = selectedBundle;
        this.ssrService = ssrService;
        this.bookingService = bookingService;
        this.ssrs = [];
        selectedBundle.serviceBundleSsrs.forEach(ssrInBundle => {
            this.flight.segments.forEach(segment => {
                this._createSsrsPerSegmentAndPassenger(ssrInBundle.ssrCode, segment).forEach(ssrPerSegmentAndPassenger => {
                    this.ssrs.push(ssrPerSegmentAndPassenger);
                });
            });
        });
    }
    _createSsrsPerSegmentAndPassenger(ssrCode, segment) {
        const segmentKey = segment.departureStation.iataCode + '_' + segment.arrivalStation.iataCode;
        for (const availableSsr of this.ssrService.availableSsrs) {
            for (const availableSsrPerSegment of availableSsr.value) {
                if (availableSsrPerSegment.key === segmentKey) {
                    for (const ssr of availableSsrPerSegment.value) {
                        if (ssr.ssrCode === ssrCode) {
                            const result = [];
                            for (let passengerNumber = 0; passengerNumber < ssr.limitPerPassenger.length; passengerNumber++) {
                                result.push(new SSRInBundlePerSegmentAndPassenger(passengerNumber, ssrCode, availableSsr.key, segment, this._getBookedSsrs(passengerNumber, segment, ssrCode), ssr.limitPerPassenger[passengerNumber].value));
                            }
                            return result;
                        }
                    }
                }
            }
        }
        return [];
    }
    _getBookedSsrs(passengerNumber, segment, ssrCode) {
        const journey = this.bookingService.getBooking().assignedSeats.journeys.items[this.flightIndex];
        const ssrNumbers = [];
        for (const seg of journey.segments.items) {
            if (seg.departureStation === segment.departureStation.iataCode && seg.arrivalStation === segment.arrivalStation.iataCode) {
                for (const paxSsr of seg.paxSsrs.items) {
                    if (paxSsr.ssrCode === ssrCode && paxSsr.passengerNumber === passengerNumber) {
                        ssrNumbers.push(paxSsr.ssrNumber);
                    }
                }
            }
        }
        return ssrNumbers;
    }
}
