import * as tslib_1 from "tslib";
import { SessionStorage } from 'ngx-webstorage';
import * as CryptoJs from 'crypto-js';
import { ConfigService } from '../../core/config.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
export class SessionService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.sessionNotification = new BehaviorSubject({ isExpired: false, isExpiring: false });
    }
    load(sessionUrl = null) {
        // this.sessionId = null;
        // this.sessionSig = null;
        // this.sessionDate = null;
        if (sessionUrl) {
            this._sessionUrl = sessionUrl;
        }
        return this.refreshSessionInternally();
    }
    set(sessionId) {
        // if (window.setSession) {
        //   return window.setSession(sessionId).then((s) => {
        //     this.setInternally(s.SessionId, s.SessionSig);
        //   },
        //     () => { }
        //   );
        // }
        this.setInternally(sessionId);
        return Promise.resolve();
    }
    isSessionValid() {
        if (!this.sessionDate || !this.sessionId || !this.sessionSig) {
            return false;
        }
        return this.sessionIsValid;
    }
    hashSession(sessionId) {
        const str = 'X-Session-Id' + sessionId + '@#$ASJDFKLJ@#$UJ@#$J%KLJZSDKLFJ'; //this.config.config.webApiKey;;
        return CryptoJs.SHA256(str).toString(CryptoJs.enc.SHA256);
    }
    keepAlive() {
        this.sessionDate = moment().toISOString();
        this.checkSession();
    }
    refreshSession() {
        // re-add the session so it can slide the expiration
        this.set(this.sessionId);
        return Promise.resolve(true);
    }
    resetSession() {
        this.loadSessionFromApi(true);
        //  return;
        // this.sessionId = null;
        // this.sessionSig = null;
        // this.sessionDate = null;
    }
    ensureSessionIsValid() {
        if (this.isSessionValid()) {
            return Promise.resolve(true);
        }
        this.resetSession();
        return this.refreshSessionInternally(true)
            .then(() => true, () => false);
    }
    refreshSessionInternally(forced = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (window.getSession) {
            //   // try get session from this tab
            //   return window.getSession(forced).then((s) => {
            //     this.setInternally(s.SessionId, s.SessionSig);
            //   },
            //     // default to normal session loading
            //     () => this.loadSessionFromApi(forced));
            // }
            if (!forced && !isNullOrUndefined(this.sessionId)) {
                yield this.set(this.sessionId);
                return;
            }
            // default to normal session loading
            return this.loadSessionFromApi(forced);
        });
    }
    setInternally(sessionId, sessionSig = null) {
        this.sessionId = sessionId;
        this.sessionSig = sessionSig || this.hashSession(this.sessionId);
        this.sessionDate = moment().toISOString();
        this.checkSession();
        this.setupSessionWarning();
    }
    loadSessionFromApi(forceRefresh = false) {
        if (!forceRefresh) {
            this.checkSession();
            if (this.isSessionValid()) {
                this.setupSessionWarning();
                return Promise.resolve();
            }
        }
        // if (!forceRefresh && this.isSessionValid()) {
        //   return Promise.resolve();
        // }
        const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
        return this.http.post(this._sessionUrl, null, { observe: 'response', headers: headers })
            .toPromise()
            .then(response => {
            this.setInternally(response.headers.get('X-Session-Id'));
        });
    }
    checkSession() {
        const now = new Date();
        const timeElapsed = now.getTime() - (new Date(this.sessionDate)).getTime();
        const difference = SessionService.SessionTimeoutLimit - timeElapsed;
        this.sessionIsValid = difference > 0;
        if (difference <= SessionService.SessionWarningLimit) {
            this.sessionNotification.next({
                isExpired: !this.sessionIsValid,
                isExpiring: this.sessionIsValid,
                timeLeftToExpire: {
                    minutes: Math.floor(difference / 1000 / 60),
                    seconds: Math.floor(difference / 1000 % 60)
                }
            });
        }
        else if (this.sessionNotification.value.isExpired || this.sessionNotification.value.isExpiring) {
            this.sessionNotification.next({
                isExpired: false,
                isExpiring: false
            });
        }
    }
    setupSessionWarning() {
        if (this.sessionVerifyInterval) {
            clearInterval(this.sessionVerifyInterval);
        }
        this.sessionVerifyInterval = setInterval(() => this.checkSession(), 1000);
    }
}
// 15minutes
SessionService.SessionTimeoutLimit = 15 * 60000;
// 1minute
SessionService.SessionWarningLimit = 1 * 60000;
tslib_1.__decorate([
    SessionStorage(),
    tslib_1.__metadata("design:type", String)
], SessionService.prototype, "sessionId", void 0);
tslib_1.__decorate([
    SessionStorage(),
    tslib_1.__metadata("design:type", String)
], SessionService.prototype, "sessionSig", void 0);
tslib_1.__decorate([
    SessionStorage(),
    tslib_1.__metadata("design:type", String)
], SessionService.prototype, "sessionDate", void 0);
