import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/config.service";
export class PushToWalletService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    retrieveInfo(continueFlow) {
        let params = new HttpParams();
        if (continueFlow) {
            params = params.append('cf', 'true');
        }
        return this.http.get(this.configService.PushToWalletUrl, { params: params }).pipe(map((response) => response.pushToWallet));
    }
    selectJourneys(selectedJourneysSellKeys) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'SelectJourneys',
                selectedJourneys: selectedJourneysSellKeys
            }
        }).pipe(map((response) => response.pushToWallet));
    }
    sendCode(details) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'SendCode',
                details: details
            }
        }).pipe(map((response) => response.pushToWallet));
    }
    confirmWithCode(code) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'ConfirmWithCode',
                confirmationCode: code
            }
        }).pipe(map((response) => response.pushToWallet));
    }
}
PushToWalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushToWalletService_Factory() { return new PushToWalletService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: PushToWalletService, providedIn: "root" });
export class PushToWalletItem {
}
export class PushToWalletModel {
}
export class PushToWalletDetails {
}
