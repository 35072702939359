import * as tslib_1 from "tslib";
import { BookingFlightSelectService } from './../../booking-flight-select/booking-flight-select.service';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { take } from 'rxjs/operators';
import { BookingChangesService } from './../../core/booking-changes.service';
import { Constants } from '../../constants';
import { OnInit, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { BookingService } from '../../core/booking.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckinSteps, BookingStepsService, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { SsrType } from '../../extras/ssr-type.enum';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../../environments/environment';
import { BookingFlowService } from '../../core/booking-flow.service';
import { ChangeBooking } from '../../core/models/booking-changes-model';
import { PaymentsService } from '../../summary/payments.service';
import { PassengerModel } from '../../core/models/passenger-model';
import { ConfigService } from 'src/app/core/config.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { VoucherRequestService } from '../../shared/voucher-request.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ProfileInfoBox } from 'src/app/profile-management/profile-info-box';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ECommerceService, Transaction } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
export class BookingInfoComponent extends ProfileInfoBox {
    constructor(bookingService, bookingFlowService, router, steps, translateService, renderer, bookingChangesService, loadingSpinnerService, activatedRoute, paymentsService, ecommerce, configService, bookingFlightSelectService, profileService, voucherRequestService, resourceService, modalService) {
        super(modalService);
        this.bookingService = bookingService;
        this.bookingFlowService = bookingFlowService;
        this.router = router;
        this.steps = steps;
        this.translateService = translateService;
        this.renderer = renderer;
        this.bookingChangesService = bookingChangesService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.activatedRoute = activatedRoute;
        this.paymentsService = paymentsService;
        this.ecommerce = ecommerce;
        this.configService = configService;
        this.bookingFlightSelectService = bookingFlightSelectService;
        this.profileService = profileService;
        this.voucherRequestService = voucherRequestService;
        this.resourceService = resourceService;
        this.currentStep = CheckinSteps.itinerary;
        this.ChangeBooking = ChangeBooking;
        this.journeys = [];
        this.passengers = [];
        this.passengersInformation = [];
        this.temporaryBookingContacts = [];
        this.passengersEmailAddresses = [];
        this.contacts = [];
        this.payments = [];
        this.bookingCurrency = '';
        this.passengerSsrs = [];
        this.totalFeeAmounts = [];
        this.changeDateActive = false;
        this.changeFlight = new ChangeFlightModel();
        this.hideModifyPaxFlights = false;
        this.noFlightAvailable = false;
        this.isCorporateBundle = false;
        this.showPaxOnLockFare = true;
        this.showExtrasOnLockFare = true;
        this.ssrChanges = [];
        this.seatChanges = [];
        this.insuranceChanges = [];
        this.hasInsuranceFee = false;
        this.hasInsuranceQueueCode = false;
        this.flightsToChange = [];
        this.paymentAccounts = [];
        this.recordLocators = [];
        this.recordCodes = [];
        this.hidePrices = true;
        this.typeCounter = { adtCount: 0, chdCount: 0, infCount: 0 };
        this.voucherRequest = true;
        this.message = "";
        this.refuntToWalletEligibilityModalId = 'refuntToWalletEligibilityModal';
        this.restrictedBookingModalId = 'restrictedBookingModal';
        this.insuranceModalId = 'insuranceModal';
        this.canceledBookingModalId = 'canceledBookingModal';
        this.restrictionMessage = "";
        this.editModalId = 'contact-infos';
        this.model = {};
        this.contactInfos = {};
        this.titles = [];
        this.languages = [];
        this.currencies = [];
        this.errors = [];
        this.phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
        this.emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        this._canDeactivate = false;
        this.airportTransferExternalUrl = null;
        steps.currentStep.next(this.currentStep);
        this.updateWindowWidth(window.innerWidth);
        this.assetsPath = environment.assetsPath;
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
            if (params) {
                this.selectedPaymentType = this.paymentsService.selectedPaymentMethod;
                this.emailSent = params['em'] === 'true';
                this.router.navigate([], { replaceUrl: true });
            }
        });
        this.isOnLockFareFlow = this.steps.flow === ApplicationFlowEnum.FinalizeLockFare;
    }
    onResize(event) {
        this.updateWindowWidth(event.target.innerWidth);
    }
    get MaggioreUrl() {
        return this.translateService.culture.match('it-IT') ?
            "https://aeroitaliamediaassets.blob.core.windows.net/itinerary/Maggiore/ita/Maggiore_aeroitalia_1200x280.jpg" :
            "https://aeroitaliamediaassets.blob.core.windows.net/itinerary/Maggiore/eng/Maggiore_aeroitalia_ENG_1200x280.jpg";
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.addRecaptchaScript();
            var restrictedAgents = yield this.resourceService.getRefundToWalletAgentDisplayRestrictions();
            this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
            this.bookingService.loadItineraryPage()
                .then(() => this.bookingFlowService.loadFlowInfo())
                .then(bookingResponse => {
                this.booking = bookingResponse;
                this.fistJourney = this.booking.convertedJourneys.journeys[0];
                this.recordLocator = this.booking.bookingDetail.recordLocator;
                this.hidePrices = this.booking.bookingDetail.pointOfSale.domainCode == 'EXT' && !this.profileService.isAgent;
                if (this.booking && this.booking.bookingDetail && this.booking.bookingDetail.recordLocators && this.booking.bookingDetail.recordLocators.length > 0) {
                    this.recordLocators = this.booking.bookingDetail.recordLocators;
                    this.isRestricted = this.isRestrictedBooking();
                }
                this.getRecordCodes();
                this.journeys = this.booking.convertedJourneys.journeys;
                this.isCanceledBookingStatus = this.checkBookingStatus();
                this.moveFlight = this.bookingFlightSelectService.isMoveFlightsCriteria(this.journeys);
                this.voucherRequest = false; // this.bookingFlightSelectService.isMoveFlightsCriteria(this.journeys);
                // this.pushToWallet = false;
                var isRestrictedAgent = restrictedAgents.some(agent => agent.agent == this.booking.bookingDetail.pointOfSale.agentCode &&
                    agent.domainCode == this.booking.bookingDetail.pointOfSale.domainCode);
                if (this.profileService.isAgent || isRestrictedAgent) {
                    this.pushToWallet = false;
                }
                else {
                    this.pushToWallet = this.journeys && this.journeys.some(j => j.segments &&
                        ((j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled')) &&
                            j.segments.every(s => s.paxSegments.every(ps => ps.liftStatus !== 'Boarded' && ps.liftStatus !== 'NoShow'))) ||
                            j.segments && j.segments.some(s => s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3) ||
                            j.segments && j.segments.some(s => (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(p => p.timeChanged) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3)));
                }
                if (this.profileService.isAgent) {
                    this.flightStatusAffectedMessage = this.getFlightStatusMessage();
                }
                if (this.booking.passengers && this.booking.passengers.items && this.booking.passengers.items.length > 0) {
                    this.passengers = this.booking.passengers.items;
                }
                if (this.booking.passengers && this.booking.passengers.items && this.booking.passengers.items.length > 0) {
                    this.hasInsuranceFee = this.passengers.some(p => p.fees && p.fees.items.some(f => f.code == "XCOVT1"));
                }
                if (this.booking.bookingQueueInfos && this.booking.bookingQueueInfos.items && this.booking.bookingQueueInfos.items.length > 0) {
                    this.hasInsuranceQueueCode = this.booking.bookingQueueInfos.items.some(q => q.queueCode == "INS");
                }
                if (this.isOnLockFareFlow) {
                    this.passengers.forEach(pax => {
                        pax.isAutoCompleted = this.isAutoCompletedField(pax.name.first)
                            && this.isAutoCompletedField(pax.name.last);
                        this.setPropsForType(pax);
                        if (pax.hasInfant) {
                            pax.infant.isAutoCompleted = this.isAutoCompletedField(pax.infant.name.first)
                                && this.isAutoCompletedField(pax.infant.name.last);
                            this.setPropsForType(pax.infant, true);
                        }
                    });
                    this.showPaxOnLockFare = this.passengers.some(x => !x.isAutoCompleted)
                        || this.passengers.filter(x => x.hasInfant).map(x => x.infant).some(x => !x.isAutoCompleted);
                    this.lockedFarePaymentDueDate = moment
                        .utc(this.booking.bookingDetail.createdDate, 'YYYY-MM-DD HH:mm:ss')
                        .add(48, 'hours')
                        .local();
                }
                if (this.isRestricted) {
                    this.contacts = this.booking.bookingContacts.items.filter(c => c.typeCode == 'G');
                }
                else {
                    this.contacts = this.booking.bookingContacts.items.filter(c => c.typeCode != 'I'); //remove the I - invoice contacts
                }
                this.passengerSsrs = this.booking.passengerSsrs.items;
                this.bookingCurrency = this.booking.convertedJourneys.currencyCode;
                this.paymentDue = this.booking.payments.balanceDue;
                this.calculatePaymentsArrayByPaymentType(this.booking.payments.items);
                this.loadMemberPromotionActivationInfo();
                const firstFutureJourney = this.journeys
                    .find(x => moment(x.segments[0].sTD).utcOffset(x.segments[0].legs[0].legInfo.deptLTV, true)
                    .isAfter(moment()));
                this.firstArrivalStation = firstFutureJourney ? firstFutureJourney.segments[firstFutureJourney.segments.length - 1].arrivalStation : '';
                this.insuranceTotal = this.calculateInsuranceTotal();
                this.getSsrChanges();
                this.setHiddenButtons(this.booking);
                this.multiJourney = this.journeys.length > 2;
            })
                .then(() => this.bookingService.manageBookingPriceBreakdown().then((response) => {
                if (response) {
                    this.fareTaxesTotal = this.calculateFareTaxesTotal(response.starterPriceBreakdown);
                    this.bundleUpgradeTotal = this.calculateBundleUpgradeTotal(response.starterPriceBreakdown);
                    this.spoilageFeesOneTotal = this.calculateExtrasSpoilageFeesTotal(response.starterPriceBreakdown);
                    this.nameChangeFeesTotal = this.calculateNameChangeFeesTotal(response.starterPriceBreakdown);
                    this.dateChangeFees = this.calculateDateChangeFeesTotal(response.starterPriceBreakdown);
                    this.spoilageFeesTwoTotal = this.calculateFareSpoilageFeesTotal(response.starterPriceBreakdown);
                    this.fareTotal = this.calculateFareTotal(response.starterPriceBreakdown);
                    this.seatTotal = this.calculateSeatTotal(response.starterPriceBreakdown);
                    this.grandTotal = this.calculateGrandTotal(response.starterPriceBreakdown);
                }
            }))
                .then(() => this.paymentDue ? this.getPaymentAccounts() : null)
                .then(() => this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId))
                .then(() => {
                let ecommerceCartItems = [];
                const storage_breakdown = sessionStorage.getItem(Constants.CartBreakdown);
                const breakdown = storage_breakdown && JSON.parse(sessionStorage.getItem(Constants.CartBreakdown));
                if (breakdown && breakdown.currentShoppingCart.flights && (window.location.href.indexOf('thank-you') > -1)) {
                    ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown, this.isOnLockFareFlow);
                    let grandTotal = 0;
                    if (this.steps.flow === ApplicationFlowEnum.Booking) {
                        grandTotal = this.grandTotal;
                    }
                    else if (this.steps.flow === ApplicationFlowEnum.ManageBooking) {
                        ecommerceCartItems.forEach(cartItem => {
                            grandTotal += Number(cartItem.price) * cartItem.quantity;
                        });
                    }
                    const transaction = new Transaction(this.recordLocator + '_' + Date.now(), ApplicationFlowEnum[this.steps.flow], grandTotal.toString(), this.fareTaxesTotal.toString(), '0', '').getItem();
                    if (this.isOnLockFareFlow) {
                        transaction.revenue = this.payments[0].paymentAmount;
                        ecommerceCartItems[0].price = '0';
                        transaction.tax = '0';
                        ecommerceCartItems.push(new ECommerceCartItem('LockFareFee', ecommerceCartItems[0].id, this.payments[0].paymentAmount, 'BlueAir', 'LockFare', 'LockFare', 1, null, null, this.ecommerce.getUserData()).getItem(false));
                    }
                    this.ecommerce.Purchases(ecommerceCartItems, transaction);
                    sessionStorage.removeItem(Constants.CartBreakdown);
                }
            });
            // this.voucherForm.form.addControl('key', new FormControl("", Validators.required));
            this.airportTransferExternalUrl = this.getAirportTransferURL();
        });
    }
    ngOnDestroy() {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }
    addRecaptchaScript() {
        window['grecaptchaCallback'] = () => {
            this.renderReCaptcha();
        };
        (function (d, s, id, obj) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));
    }
    renderReCaptcha() {
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
            'sitekey': '6LfOoPYUAAAAAOEugDbmqKTiUJaxZ_IgMyB-RDA4',
            'callback': (response) => {
                this.message = response;
                //return response;
            }
        });
    }
    requestVoucher() {
        this.voucherModal.openPopup(modal => {
            if (this.message.length != 0) {
                this.voucherRequestService.createRequest(this.recordLocator, this.message);
            }
            else {
                modal.IsOpen = true;
            }
        });
    }
    calculatePaymentsArrayByPaymentType(paymentsItems) {
        // iterate through all payment items from booking
        paymentsItems.forEach(paymentItem => {
            // check and find if the current iteration item is of type wallet
            const walletPaymentItem = paymentItem.paymentFields
                .find(paymentField => BookingInfoComponent.WalletValue.indexOf(paymentField.fieldValue) > -1);
            // check and find if in this.payments array an item on type wallet exists.
            const existingPaymentWallet = this.payments.find(payment => payment.paymentFields.find(paymentField => BookingInfoComponent.WalletValue.indexOf(paymentField.fieldValue) > -1));
            // if it does add the amount of walletPaymentItem on top of it and not create a new item in the this.payment array
            if (walletPaymentItem && existingPaymentWallet) {
                paymentItem.isWallet = true;
                existingPaymentWallet.paymentAmount += paymentItem.paymentAmount;
            }
            else {
                // if it does not create a new item of type wallet on this.payment array
                if (walletPaymentItem) {
                    paymentItem.isWallet = true;
                }
                else {
                    // if payment is not of type wallet add it on this.payments array with isWallet prop false
                    paymentItem.isWallet = false;
                }
                this.payments.push(Object.assign({}, paymentItem));
            }
        });
    }
    setHiddenButtons(bookingResponse) {
        this.isCorporateBundle = bookingResponse.bundleDetail.bundleCodes
            .reduce((a, b) => a.concat(b), [])
            .map(x => x.value)
            .some(x => Constants.CorporateBundles.includes(x));
        this.noFlightAvailable = bookingResponse.convertedJourneys.journeys.every(x => !this.isJourneyChangeAllowed(x));
        // at least one passenger has not been checked in on any flight
        let allPaxCheckedIn = true;
        const checkedInPaxJourneys = bookingResponse.assignedSeats.journeys.items.map(x => x.segments.items
            .reduce((a, b) => a.concat(b), [])
            .map(y => y.passengerSegments.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(z => z.liftStatus !== Constants.NotCheckedIn)
            .reduce((a, b) => a.concat(b), [])
            .map(t => t.passengerNumber));
        //check if at least one journey has no pax checked-in
        allPaxCheckedIn = checkedInPaxJourneys.every(data => data.length !== 0);
        this.hideModifyPaxFlights = allPaxCheckedIn || this.noFlightAvailable;
    }
    getSsrChanges() {
        this.bookingChangesService.getSsrChanges(this.journeys, this.passengers, null, true);
        this.changesSubscription = this.bookingChangesService.changesObs.subscribe((changes) => {
            if (changes) {
                this.ssrChanges = this.bookingChangesService.ssrChanges;
                if (this.isOnLockFareFlow) {
                    this.showExtrasOnLockFare = this.ssrChanges.some(x => x.passengerSsrsByType.length > 0);
                }
                this.seatChanges = this.bookingChangesService.seatChanges;
                this.insuranceChanges = this.bookingChangesService.insuranceChanges;
                this.totalFeeAmounts = this.getTotalFeeAmounts(this.passengers);
            }
        });
    }
    getSegCount() {
        return "extrasSummary_wrapper segm" + this.journeys.map(p => p.segments).reduce((a, b) => a.concat(b), []).length;
    }
    isBookingOnHold() {
        if (this.booking)
            return this.booking.bookingDetail.status == "Hold";
        return false;
    }
    getStation(i, journey) {
        if (journey) {
            if (i == 0)
                return journey.segments[0].departureStation;
            if (journey.segments.length === 1)
                return journey.segments[0].arrivalStation;
            return journey.segments[journey.segments.length - 1].arrivalStation;
        }
    }
    updateWindowWidth(width) {
        this.mobileMatches = width < Constants.mobileScreenWith;
    }
    changeThisFlight(journey, index, event) {
        var target = event.target || event.srcElement;
        if (target.checked) {
            this.flightsToChange.push(journey);
        }
        else if (this.flightsToChange.indexOf(journey) !== -1) {
            this.flightsToChange.splice(this.flightsToChange.indexOf(journey), 1);
        }
        this.bookingService.flightsToChangeObs.next(this.flightsToChange);
        this.ignoreConnectionErrors = true;
        this.changeFlight.toggleJourney(index, this.getStation(0, journey), this.getStation(1, journey), moment(journey.segments[0].sTD));
        setTimeout(() => this.ignoreConnectionErrors = false, 0);
    }
    initPushToWallet(event) {
        if (!this.isRefundToWalletEligibleSegment()) {
            this.refuntToWalletEligibilityModal.openPopup(this.refuntToWalletEligibilityModalId);
            return;
        }
        if (this.journeys.some(j => j.segments.some(s => s.paxSSRs.find(ssr => ssr.sSRCode.match("INS1"))))) {
            this.insuranceAlertModal.openPopup((isOk) => {
                this._canDeactivate = isOk;
                if (isOk) {
                    this.router.navigate(['/ptw']);
                }
            });
            return;
        }
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        this.router.navigate(['/ptw']);
    }
    isRefundToWalletEligibleSegment() {
        return (this.journeys.some(j => j.segments && j.segments.some(s => s.liftStatus !== 'CheckedIn'))) ? true : false;
    }
    isJourneyChangeAllowed(journey) {
        for (let i = 0; i < journey.segments.length; i++) {
            if (journey.segments[i].segmentType === 'P'
                || journey.segments[i].paxSegments.some(ps => ps.liftStatus && ps.liftStatus !== Constants.NotCheckedIn)) {
                return false;
            }
        }
        const cancelDepartureTimeLimit = this.booking.convertedJourneys.cancelDepartureTimeLimit.split(':');
        return moment(journey.segments[0].legs[0].sTD).utcOffset(journey.segments[0].legs[0].legInfo.deptLTV, true)
            .isSameOrAfter(moment().add(Number(cancelDepartureTimeLimit[0]), 'hour')
            .add(Number(cancelDepartureTimeLimit[1]), 'minute')
            .add(Number(cancelDepartureTimeLimit[2]), 'seconds'));
    }
    getFlightDates() {
        if (this.booking) {
            let departureDate = this.booking.convertedJourneys.journeys.map(s => s.segments).reduce((a, b) => a.concat(b), []).map(s => moment(s.sTD)).reduce((a, b) => b.isBefore(a) ? b : a);
            let arrivalDate = this.booking.convertedJourneys.journeys.map(s => s.segments).reduce((a, b) => a.concat(b), []).map(s => moment(s.sTA)).reduce((a, b) => b.isBefore(a) ? a : b);
            //Fri, 21 Sep 2018
            if (this.booking.convertedJourneys.journeys[0].journeyTripType == Constants.OneWayTrip)
                return departureDate.format("dddd, DD MMM  YYYY");
            else
                return "" + departureDate.format("dddd, DD MMM  YYYY") + " - " + arrivalDate.format("dddd, DD MMM  YYYY");
        }
    }
    getDepartureDate(journey) {
        return moment(journey.segments[0].sTD).format("dddd ,DD MMM  YYYY");
    }
    changeBooking(page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.bookingFlowService.loadFlowInfo().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.setRestrictionMessage();
                // to do: handle event from change flight component
                this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
                // const snapShotResult = await this.bookingService.clearSession()
                this.bookingService.createSnapshot().then(snapshot => {
                    if (snapshot) {
                        this.bookingService.refresh(true).then(booking => {
                            if (booking) {
                                this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
                                switch (page) {
                                    case ChangeBooking.flight:
                                        if (this.hasInsuranceFee || this.hasInsuranceQueueCode) {
                                            this.page = page;
                                            this.insuranceMesage = "You booked Travel Insurance for this booking. Please be aware that you should contact our insurance provider to update your policy in case the dates of your trip change.";
                                            this.insuranceModal.openPopup(this.insuranceModalId);
                                        }
                                        else {
                                            if (this.isRestricted) {
                                                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
                                            }
                                            this.changeDateActive = true;
                                        }
                                        break;
                                    case ChangeBooking.luggage:
                                        if (this.areCanceledBookingStatusAll()) {
                                            this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                                            this.bookingService.clearSnapshot();
                                            break;
                                        }
                                        this.steps.goToStep(CheckinSteps.luggage);
                                        break;
                                    case ChangeBooking.seats:
                                        if (this.areCanceledBookingStatusAll()) {
                                            this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                                            this.bookingService.clearSnapshot();
                                            break;
                                        }
                                        this.steps.goToStep(CheckinSteps.seat);
                                        break;
                                    case ChangeBooking.passengers:
                                        if (this.hasInsuranceFee || this.hasInsuranceQueueCode) {
                                            this.page = page;
                                            this.insuranceMesage = "You booked Travel Insurance for this booking. Please be aware that you should contact our insurance provider to update your policy in case the name of the passengers from your trip change.";
                                            this.insuranceModal.openPopup(this.insuranceModalId);
                                        }
                                        else {
                                            if (this.isRestricted) {
                                                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
                                                this.bookingService.clearSnapshot();
                                            }
                                            else if (this.isCanceledBookingStatus || this.areCanceledBookingStatusAll()) {
                                                this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                                                this.bookingService.clearSnapshot();
                                            }
                                            this.bookingFlowService.scrollToPassengersObs.next(this.page);
                                            this.steps.goToStep(CheckinSteps.passengers);
                                        }
                                        break;
                                    case ChangeBooking.contact:
                                        if (this.isRestricted) {
                                            this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
                                            break;
                                        }
                                        else if (this.isCanceledBookingStatus || this.areCanceledBookingStatusAll()) {
                                            this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                                            this.bookingService.clearSnapshot();
                                            break;
                                        }
                                        this.bookingFlowService.scrollToPassengersObs.next(page);
                                        this.steps.goToStep(CheckinSteps.passengers);
                                        break;
                                }
                            }
                        });
                    }
                });
            }));
        });
    }
    isRestrictedBooking() {
        return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
    }
    checkBookingStatus() {
        // to be merged
        for (const j of this.journeys) {
            for (const s of j.segments) {
                for (const l of s.legs) {
                    if (l.legInfo.status === "Canceled") {
                        this.departureJourneySegment = s.departureStation;
                        this.arrivalJourneySegment = s.arrivalStation;
                        return true;
                    }
                }
            }
        }
        return false;
    }
    areCanceledBookingStatusAll() {
        // to be merged
        let canceledJourneysCounter = 0;
        let isCanceledJourney;
        for (const j of this.journeys) {
            for (const s of j.segments) {
                for (const l of s.legs) {
                    if (l.legInfo.status === "Canceled") {
                        isCanceledJourney = true;
                    }
                }
            }
            if (isCanceledJourney) {
                canceledJourneysCounter++;
                isCanceledJourney = false;
            }
        }
        if (canceledJourneysCounter >= 2) {
            this.areCanceledFlights = true;
            return true;
        }
        return false;
    }
    getRecordCodes() {
        for (const r of this.recordLocators) {
            this.recordCodes.push(r.recordCode);
        }
    }
    setRestrictionMessage() {
        if (this.isRestricted) {
            if (this.profileService.isAgent) {
                this.restrictionMessage = "This booking does not allow changes through the website. Please use the original booking channel.";
            }
            else if (this.recordLocators.some(r => r.owningSystemCode.includes("ACE"))) {
                this.restrictionMessage = "This booking does not allow changes through the website since there are other carriers involved in the itinerary. Please call to the phone provided in the booking confirmation.";
            }
            else {
                this.restrictionMessage = "This booking does not allow changes through the website. Please contact your Travel Agency. Note any additional service added on our website should be booked again after changing your flight.";
            }
        }
    }
    setPassengerInformation(passenger = {}, inputValue = "", isPhoneNumber = false) {
        this.passengersInformation.push({ passenger, inputValue, isPhoneNumber });
    }
    edit() {
        this.setPassengersAddressesDefaultData();
        this.openModal();
    }
    save() {
        this.setPassengersInformation();
        this.saveBookingContacts();
        this.bookingService.savePassengers(this.passengers, [])
            .then(() => { this.steps.goToStep(CheckinSteps.summary); });
        this.closeModal();
    }
    cancel() {
        this.closeModal();
    }
    setPassengersAddressesDefaultData() {
        for (let p = 0; p < this.passengers.length; p++) {
            if (!this.passengers[p].addresses.items || this.passengers[p].addresses.items.length < 1) {
                this.passengers[p].addresses.items.push({
                    emailAddress: "",
                    phoneNumber: "",
                    typeCode: "C"
                });
            }
        }
    }
    setPassengersInformation() {
        this.passengers.forEach(p => {
            this.passengersInformation.forEach(i => {
                if (p.passengerNumber == i.passenger.passengerNumber) {
                    if (i.isPhoneNumber) {
                        p.addresses.items[0].phoneNumber = i.inputValue.trim();
                    }
                    else {
                        p.addresses.items[0].emailAddress = i.inputValue.trim();
                    }
                    this.setBookingContact(p);
                }
            });
        });
    }
    setBookingContact(passenger = {}) {
        let contact = { name: {} };
        contact.typeCode = Constants.TypeCodes[passenger.passengerNumber];
        contact.homePhone = passenger.addresses.items[0].phoneNumber ? passenger.addresses.items[0].phoneNumber : "";
        contact.emailAddress = passenger.addresses.items[0].emailAddress ? passenger.addresses.items[0].emailAddress : "";
        contact.name.first = passenger.name.first ? passenger.name.first : "";
        contact.name.last = passenger.name.last ? passenger.name.last : "";
        contact.distributionOption = "Email";
        contact.addressLine1 = "";
        contact.addressLine2 = "";
        contact.addressLine3 = "";
        contact.city = "";
        contact.companyName = "";
        contact.countryCode = "";
        contact.countryProvinceState = "";
        contact.customerNumber = "";
        contact.faxNumber = "";
        contact.otherPhone = "";
        contact.postalCode = "";
        contact.provinceState = "";
        contact.receivePromotional = false;
        contact.sourceOrganization = "";
        contact.workPhone = "";
        this.temporaryBookingContacts.push(contact);
    }
    saveBookingContacts() {
        let steadyBookingContacts = [];
        this.temporaryBookingContacts.reverse().forEach(s => {
            if (!steadyBookingContacts.some(t => t.typeCode == s.typeCode)) {
                steadyBookingContacts.push(s);
            }
        });
        steadyBookingContacts.forEach(contact => {
            this.bookingService.saveContact(contact);
        });
    }
    formatDate(date, format) {
        return moment(date).format(format);
    }
    /**
     * Get total fee amounts from passengers as an array filtered by SSRs and amount paid
     * @param passengers Array of passengers
    */
    getTotalFeeAmounts(passengers) {
        const paxFeeTotals = new Array();
        for (const ssrType in SsrType) {
            if (isNaN(Number(ssrType))) {
                const type = SsrType[ssrType.toString()];
                if (type !== SsrType.Seat) {
                    const ssrTypeList = this.getSSRTypeListFromNest(type);
                    if (ssrTypeList.length > 0) {
                        const paxFeeItems = this.getFeeItemsFromPassengersBySSRType(passengers, ssrTypeList);
                        const feeTotal = this.calculateFeeTotalForSSRType(paxFeeItems, type);
                        if (feeTotal) {
                            paxFeeTotals.push(feeTotal);
                        }
                    }
                }
            }
        }
        return paxFeeTotals;
    }
    getInfantPriceTotal() {
        return this.passengers
            .map(x => x.fees)
            .map(x => x.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(x => x.code === Constants.InfantSsrCode)
            .map(x => x.serviceCharges)
            .map(x => x.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(z => z.chargeType !== BookingInfoComponent.IncludedTax)
            .reduce((a, b) => a + b.amount, 0);
    }
    /**
     * Reducing function for total fee using SSRs and amount paid
     * @param prev
     * @param next
     */
    reduceArrayBySSRAndAmount(prev, next) {
        var key = next.chargeCode;
        if (!prev[key]) {
            prev[key] = next;
        }
        else {
            prev[key].amount += next.amount;
            prev[key].foreignAmount += next.foreignAmount;
        }
        return prev;
    }
    /**
     * Calculate fare taxes total + infants
     */
    calculateFareTaxesTotal(priceBreakdown) {
        let infantTotal = this.getInfantPriceTotal();
        return (priceBreakdown.fareTaxAndFees + infantTotal) || 0;
    }
    /**
     * Calculate fare total
     */
    calculateFareTotal(priceBreakdown) {
        return priceBreakdown.fareTotal || 0;
    }
    calculateBundleUpgradeTotal(priceBreakdown) {
        return priceBreakdown.bundleFees || 0;
    }
    calculateExtrasSpoilageFeesTotal(priceBreakdown) {
        return priceBreakdown.spoilageFees || 0;
    }
    calculateFareSpoilageFeesTotal(priceBreakdown) {
        return (priceBreakdown.serviceFees + priceBreakdown.serviceFeeAdjustments) || 0;
    }
    calculateDateChangeFeesTotal(priceBreakdown) {
        return priceBreakdown.cancelChangeFees || 0;
    }
    calculateNameChangeFeesTotal(priceBreakdown) {
        return priceBreakdown.nameChangeFees || 0;
    }
    /**
     * Calculate seat total
     */
    calculateSeatTotal(priceBreakdown) {
        return priceBreakdown.seatFees || 0;
    }
    /**
     * Calculate insurance total
     */
    calculateInsuranceTotal() {
        return Math.abs(this.passengers
            .map(x => x.fees)
            .map(x => x.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(x => x.code === Constants.InsuranceFeeCode)
            .map(x => x.serviceCharges)
            .map(x => x.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(z => z.chargeType !== BookingInfoComponent.IncludedTax)
            .reduce((a, b) => a + b.amount, 0)) || 0;
    }
    /**
     * Calculate grand total
     */
    calculateGrandTotal(priceBreakdown) {
        return priceBreakdown.grandTotalPrice || 0;
    }
    /**
     * Get SSR type list from nest SSR
     * @param ssrType The nest SSR type
     */
    getSSRTypeListFromNest(ssrType) {
        return this.bookingChangesService.getSsrTypeList(ssrType);
    }
    /**
     * Get all fee items from passengers by certain SSR type
     * @param passengers Passengers array
     * @param ssrTypeList SSR types array
     */
    getFeeItemsFromPassengersBySSRType(passengers, ssrTypeList) {
        return passengers.map(p => p.fees.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(function (item) { return ssrTypeList.includes(item.ssrCode); })
            .map(x => x.serviceCharges.items)
            .reduce((a, b) => a.concat(b), [])
            .filter(z => z.chargeType !== BookingInfoComponent.IncludedTax)
            .reduce(this.reduceArrayBySSRAndAmount, {});
    }
    /**
     * Calculate fee total for a certain SSR type
     * @param paxFeeItems Passenger fee items
     * @param ssrType SSR type to filter
     */
    calculateFeeTotalForSSRType(paxFeeItems, ssrType) {
        let arrayvalues = Object.values(paxFeeItems);
        let sumOfFees = arrayvalues.reduce((a, b) => a + b.amount, 0);
        if (sumOfFees <= 0) {
            return null;
        }
        var feeObject = {
            'total': sumOfFees,
            'ssr': this.translateService.instant(SsrType[ssrType], "itinerary", ["ssr.name"])
        };
        return feeObject;
    }
    toggleVisibility(extrasToggle) {
        if (extrasToggle.classList.contains('show')) {
            this.renderer.removeClass(extrasToggle, 'show');
        }
        else {
            this.renderer.addClass(extrasToggle, 'show');
        }
    }
    getPaymentAccounts() {
        if (this.selectedPaymentType) {
            switch (this.selectedPaymentType) {
                case 'OBT':
                    this.selectedPaymentTypeDescription = 'bank transfer';
                    break;
                case 'CD':
                    this.selectedPaymentTypeDescription = 'cash deposit';
                    break;
                default:
                    this.selectedPaymentTypeDescription = '';
                    break;
            }
            return this.paymentsService.getPaymentAccounts(this.selectedPaymentType, this.bookingCurrency).then((paymentAccounts) => {
                this.paymentAccounts = paymentAccounts;
            });
        }
        return Promise.resolve();
    }
    isNextDay(departureDate, arrivalDate) {
        if (moment(departureDate).date() !== moment(arrivalDate).date()) {
            return true;
        }
    }
    print() {
        window.print();
    }
    subtractTimezoneOffset(date, offset) {
        return moment(date).utcOffset(offset, true);
    }
    isAutoCompletedField(value) {
        return value && PassengerModel.checkNameFormat(String(value));
    }
    setPropsForType(pax, isInfant) {
        let paxType = null;
        if (pax.typeInfo) {
            paxType = pax.typeInfo.paxType.substring(0, 3);
            this.typeCounter[paxType] = (this.typeCounter[paxType] || 0) + 1;
            pax.passengerNumberByType = this.typeCounter[paxType];
        }
        if (isInfant) {
            pax.isInfant = true;
        }
        else {
            switch (paxType) {
                case Constants.AdultPaxType:
                    pax.isAdult = true;
                    break;
                case Constants.YoungAdultPaxType:
                    pax.isYouth = true;
                    break;
                case Constants.TeenPaxType:
                    pax.isTeen = true;
                    break;
                case Constants.ChildrenPaxType:
                    pax.isChild = true;
                    break;
            }
        }
    }
    getAirportTransferURL() {
        let language = "";
        const selectedLanguage = this.translateService.culture ? this.translateService.culture : 'en-GB';
        switch (selectedLanguage) {
            case 'en-GB':
            case 'ro-RO':
                language = `en/partner/aeroitalia/offer.html`;
                break;
            default:
                language = 'partner/aeroitalia/offerta.html';
        }
        return `https://www.maggiore.it/${language}`;
    }
    getAirportParkingURL() {
        const selectedLanguage = this.translateService.culture ? this.translateService.culture : 'en-GB';
        return `http://blueair.parkcloud.com/${selectedLanguage}`;
    }
    loadMemberPromotionActivationInfo() {
        if (!this.paymentDue || this.paymentDue === 0) {
            this.bookingService.loadMemberPromotionActivationInfo()
                .then(activationData => {
                if (!activationData) {
                    this.bbActivationInfo = null;
                    return;
                }
                this.bbActivationInfo = {
                    promoId: activationData.promoId,
                    activationId: activationData.activationId,
                    url: this.configService.config.corporateUrl + `m/shop/bluebenefits/activate/${activationData.activationId}`
                };
            });
        }
        else {
            this.bbActivationInfo = null;
        }
    }
    getFlightStatusMessage() {
        let canceledJourneys = this.journeys && this.journeys.some(j => j.segments &&
            (j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled')) &&
                j.segments.every(s => s.paxSegments.every(ps => ps.liftStatus !== 'Boarded' && ps.liftStatus !== 'NoShow'))));
        let scheduleChangedJourneys = this.journeys && this.journeys.some(j => j.segments &&
            (j.segments && j.segments.some(s => s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3) ||
                j.segments && j.segments.some(s => (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(p => p.timeChanged) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3)));
        let refunds = this.booking.comments.items.filter(c => c.text.match("process-started"));
        if (canceledJourneys) {
            return "Affected by a cancellation";
        }
        else if (scheduleChangedJourneys) {
            return "Affected by a schedule change";
        }
        else if (refunds.length > 0) {
            return "Canceled";
        }
        else {
            return this.booking.bookingDetail.status;
        }
    }
    checkInsuranceAcknowledgment() {
        this.insuranceModal.ok();
        if (this.page == ChangeBooking.flight) {
            this.insuranceModal.ok();
            if (this.isRestricted) {
                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
            }
            this.changeDateActive = true;
        }
        else if (this.page == ChangeBooking.passengers) {
            this.insuranceModal.ok();
            if (this.isRestricted) {
                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
                this.bookingService.clearSnapshot();
            }
            else if (this.isCanceledBookingStatus || this.areCanceledBookingStatusAll()) {
                this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                this.bookingService.clearSnapshot();
            }
            this.bookingFlowService.scrollToPassengersObs.next(this.page);
            this.steps.goToStep(CheckinSteps.passengers);
        }
    }
}
BookingInfoComponent.WalletValue = ['WLTVO', 'VIRAL'];
BookingInfoComponent.IncludedTax = 'IncludedTax';
export class ChangeFlightModel {
    constructor() {
        this.journeys = [];
    }
    toggleJourney(index, departure, arrival, date) {
        if (this.journeys.filter(p => p.index === index).length > 0) {
            const i = this.journeys.findIndex(p => p.index === index);
            this.journeys.splice(i, 1);
        }
        else {
            this.journeys.push({ index: index, departure: departure, arrival: arrival, date: date });
        }
        if (this.journeys && this.journeys.length > 1) {
            this.journeyType = Constants.RoundTrip;
            const first = this.journeys.find(p => p.index === 0);
            this.departureStation = first.departure;
            this.arrivalStation = first.arrival;
            this.outboundDate = moment(first.date).format("YYYY/MM/DD");
            this.inboundDate = this.journeys.find(p => p.index === 1).date;
        }
        else if (this.journeys.length === 1) {
            const theJourney = this.journeys[0];
            this.arrivalStation = '';
            this.departureStation = '';
            this.departureStation = theJourney.departure;
            this.arrivalStation = theJourney.arrival;
            this.outboundDate = moment(theJourney.date).format("YYYY/MM/DD");
            this.journeyType = Constants.OneWayTrip;
        }
        else {
            this.arrivalStation = '';
            this.departureStation = '';
            this.outboundDate = '';
            // this.journeyType  = 'OneWay';
        }
    }
    reset() {
        this.arrivalStation = '';
        this.departureStation = '';
        this.outboundDate = '';
        this.journeys.length = 0;
    }
}
